.preferences-page {
  h2 {
    font-size: 23px;
    font-family: $semibold;
    color: $gray400;
    margin-bottom: 0;
  }
}

.remove-button {
  background: none;
  border: none;
  color: $orange;
  font-family: $bold;
  font-size: 25px;
  margin-left: 10px;
}

.hand-picked-input {
  input {
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 300px;
  }
  ul {
    border: 1px solid #ccc;
    width: 300px;
  }
  li {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: $gray100;
    }
  }
}

.overall-weight {
  input {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 5px;
    width: 100px;
    margin-left: 20px;
  }
}
