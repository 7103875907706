/************ FONT FAMILY *************/
@font-face {
  font-family: 'black';
  src: url('../fonts/ProximaNova-Black.otf');
}

@font-face {
  font-family: 'extrabold';
  src: url('../fonts/ProximaNova-Extrabold.otf');
}

@font-face {
  font-family: 'semibold';
  src: url('../fonts/ProximaNova-Semibold.otf');
}

@font-face {
  font-family: 'bold';
  src: url('../fonts/ProximaNova-Bold.otf');
}

@font-face {
  font-family: 'boldIt';
  src: url('../fonts/ProximaNova-BoldIt.otf');
}

@font-face {
  font-family: 'regular';
  src: url('../fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'light';
  src: url('../fonts/ProximaNova-Light.otf');
}

@font-face {
  font-family: 'lightItalic';
  src: url('../fonts/ProximaNova-LightItalic.otf');
}

/************ FONT SIZES *************/
$font-size-xxxs: 11px;
$font-size-xxs: 13px;
$font-size-xs: 15px;
$font-size-s: 20px;
$font-size-m: 25px;
$font-size-l: 35px;
$font-size-xl: 48px;
$font-size-xxl: 65px;

/************ FONT WEIGHT *************/
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;

$lightIt: 'lightItalic';
$light: 'light';
$regular: 'regular';
$italic: 'regularItalic';
$bold: 'bold';
$boldIt: 'boldIt';
$semibold: 'semiBold';
$extrabold: 'extrabold';

h1,
h2,
h3,
p,
div {
  color: $gray400;
}

h1,
h2,
h3,
a {
  line-height: 1.2;
}

.regular {
  font-family: regular;
}

.bold {
  font-family: bold;
}

.regular {
  font-family: regular;
}

.extra-bold {
  font-family: extrabold;
}

.thin-text {
  font-family: light;
}
