/************ MARGIN SIZES *************/
$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-s: 15px;
$spacing-m: 20px;
$spacing-l: 30px;
$spacing-xl: 50px;
$spacing-xxl: 80px;

/************ MEDIA SCREEN SIZES *************/
$screen-size-xxs: 350px;
$screen-size-xs: 400px;
$screen-size-s: 500px;
$screen-size-m: 600px;
$screen-size-l: 700px;
$screen-size-xl: 1000px;
$screen-size-xxl: 1200px;
$screen-size-xxxl: 1600px;

$black-background: false;
