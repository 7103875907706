$img-side: 110px;

.concept-menu-item-select-input {
  .selected-item-container {
    display: flex;
  }
  .selected-item-left {
    flex: 2;
  }
  .selected-item-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .item-image-container {
    width: $img-side;
    height: $img-side;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .item-image-placeholder-container {
    border-radius: 10px;
    width: $img-side;
    height: $img-side;
    background-color: lighten(gray, 45%);
    display: flex;
    justify-content: center;
    align-items: center;
    .no-image-copy {
      text-align: center;
      padding: 5px;
      .title {
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 5px;
      }
      .description {
        font-size: 80%;
        line-height: 1.2;
      }
    }
  }
}
