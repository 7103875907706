.ReactTags__tag {
  background: $orange;
  color: #fff;
  padding: 0 $spacing-xxs;
  border-radius: 3px;
  margin-right: $spacing-xxs;
  margin-bottom: $spacing-xxs;
  display: inline-block;
  font-size: $font-size-xxs;
}

.ReactTags__remove {
  color: #fff;
  font-family: $bold;
  padding: $spacing-xxs;
  font-size: $font-size-xs;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.ReactTags__tags {
  color: $gray300;
  width: 100%;
  background: #fafbff;
  font-family: $regular;
  border: 1px solid gray;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.4;
}

.ReactTags__tagInputField {
  width: 100%;
  background: none;
}
