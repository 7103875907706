.panel-default,
.list-group {
  pre {
    line-height: 1.2;
    background: none;
    border: none;
  }
}

.panel {
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.22);
}

.panel-default > .panel-heading {
  color: $gray300;
  background: #eee;
}
