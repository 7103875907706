.page-select {
  background: none;
  border: 1px solid $gray200;
  border-radius: 4px;
  padding: 7px;
}

.search-input {
  input {
    background: none;
    border: 1px solid $gray200;
    border-radius: 4px;
    padding: 7px;
    width: 150px;
  }
}

.search-input-icon {
  position: relative;
  input {
    width: 150px;
    padding: 7px;
    padding-left: 35px;
    border: 1px solid $gray200;
    border-radius: 4px;
  }
  &:after {
    content: '';
    position: absolute;
    background: url('../images/search.png') no-repeat;
    background-size: 18px;
    width: 18px;
    height: 18px;
    top: 8px;
    left: 8px;
    opacity: 0.5;
    pointer-events: none;
  }
}
