.slack-auth-data-customer-section {
  margin-bottom: 20px;

  .slack-auth-data-customer-label {
    color: $gray400;
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .slack-auth-data-customer-data {
    font-family: 'regular';
    margin-bottom: 5px;
  }

  .slack-auth-data-customer-button {
    border: none;
    background-color: $gray100;
    margin: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'bold';
  }
}

.slack-auth-data-link-section {
  .link-label {
    color: $gray400;
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .slack-auth-data-link {
    font-family: 'bold';
    cursor: pointer;
    color: blue;
  }
}

.slack-auth-data-buttons-container {
  display: flex;
  justify-content: flex-end;
}
