.edit-group-order-staffing-fee-settings-modal {
  .info-container {
    margin-bottom: 20px;
    p {
      font-family: 'lightitalic';
      color: $gray400;
    }
  }

  .all-configs-container {
    border-top: solid 1px $gray200;
    padding-top: 20px;

    .fee-value {
      max-width: 100px;
      & > div {
        margin-bottom: 0;
      }
    }
  }

  .location-container {
    background-color: lighten($gray200, 10%);
    padding: 10px 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    .location-title {
      font-family: bold;
      margin-bottom: 10px;
    }
  }

  .global-config-container {
    margin-bottom: 20px;
    border-bottom: solid 1px $gray200;
    padding-bottom: 20px;
  }

  .fee-inputs-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .fee-value {
      margin-right: 20px;
      flex: 1;
    }

    .total-fee-container {
      flex: 1.5;
      input {
        cursor: text !important;
        opacity: 1 !important;
      }
    }

    .total-fee {
      .total-fee-label {
        font-family: bold;
        text-transform: uppercase;
      }
      .total-fee-value {
        height: 35px;
        border: solid 1px black;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .link-text {
      margin-right: 20px;
    }
  }
}
