.add-promo-container {
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;
  @include flexbox;
  @include align-items(center);
  button {
    margin-right: $spacing-s;
  }
}

.promocode-table {
  input {
    max-width: 80px;
  }
  input[type='submit'] {
    display: block;
    max-width: 120px;
  }
}

.table > tbody > tr > .promocode-header-container {
  padding: 0;
  border-top: 0;
}

.table > tbody > tr > th {
  border-top: 0;
}

.table-hover > tbody > .promocode-table-headers:hover {
  background: $gray100;
}

.promocode-header {
  text-align: center;
  background: $gray100;
  font-family: $semibold;
  padding: $spacing-xxs;
  padding-top: $spacing-xs;
  font-size: $font-size-s;
  //border-radius: 10px 10px 0 0;
}

.promocode-table-headers {
  background: $gray100;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  // > th:first-child {
  //   border-bottom-left-radius: 10px;
  // }
  // >th:last-child {
  //   border-bottom-right-radius: 10px;
  // }
}

.no-promocodes {
  text-align: center;
  font-size: $font-size-xs;
  color: $gray200;
  padding: $spacing-m;
  background: lighten($gray100, 10%);
}

.promo-input-fields {
  .promo-column {
    @include flexbox;
    @include align-items(center);
    width: 50%;
  }
  .promo-column:last-child {
    @include justify-content(flex-end);
  }
  .form-control {
    margin-left: 120px;
    width: 100px;
  }
  .dropdown .input-group-btn {
    border-radius: 3px;
  }
  button {
    border-radius: 3px !important;
    width: 110px;
  }
  .dropdown-toggle .btn .btn-default {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  input[type='submit'] {
    background: $violet;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0 $spacing-s;
    font-size: $font-size-xxs;
  }
  .sales-code {
    width: auto;
    margin-right: $spacing-m;
  }
  margin-bottom: 15px;
}

.table > thead > tr > th,
.table > tbody > tr > td {
  padding-right: $spacing-m;
}

.table > thead > tr > th:last-child,
.table > tbody > tr > td:last-child {
  padding-right: 0;
}

.promocode-button-container {
  position: absolute;
  margin-top: 20px;
  margin-left: -20px;
  display: flex;
}

.promocode-button-container__inner {
  display: flex;
}

.promocode-table {
  overflow: auto;
  max-height: 500px;
}
