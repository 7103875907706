.export-csv-button {
  background: #00acc1;
  white-space: nowrap;
  font-family: bold;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
  color: #fff !important;
  text-decoration: none !important;
  transition: 0.2s ease-in-out all;
  &:hover {
    filter: brightness(90%);
  }
}

.export-csv-button a:hover,
.export-csv-button a:link,
.export-csv-button a:active,
.export-csv-button a:visited,
.export-csv-button a {
  color: #fff !important;
  text-decoration: none !important;
}
