.padding-20 {
  padding: 20px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-25 {
  margin-top: 25px;
}
.flatpickr-calendar {
  z-index: 1000;
}
.flatpickr-wrapper {
  width: 100%;
}
.flatpickr:hover {
  cursor: text;
}
.flatpickr.active {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.flatpickr-day {
  max-width: none;
  width: 14.28%;
}

.input-field-container {
  @include flexbox;
  @include flex-direction(column);
  text-align: left;
  margin-bottom: $spacing-m;
  input,
  textarea {
    width: 100%;
    background: lighten($gray100, 2%);
    padding: $spacing-xs;
    border-radius: 3px;
    border: 1px solid lighten($gray400, 20%);
    &:disabled {
      background: darken($gray100, 10%);
      color: darken($gray100, 40%);
      pointer-events: none;
    }
  }
  select {
    border-radius: 3px;
    border: 1px solid lighten($gray400, 20%);
    height: 40px;
  }
}

.input-small {
  input {
    width: 100px;
  }
}

.select-contact {
  margin-bottom: $spacing-m;
}

.order-modal {
  .panel {
    box-shadow: none;
    border: none;
    margin-bottom: 0;
  }
  .panel-body {
    padding: 0;
    h4 {
      font-size: $font-size-s;
      text-align: left;
      font-family: $bold;
      color: $gray400;
    }
  }
}

.form-section {
  @include flexbox;
  @include justify-content(flex-start);
  @include flex-direction(column);
  h2 {
    font-size: $font-size-s;
    text-align: left;
  }
  > div {
    width: 100%;
  }
}

.form-section-fields-container {
  width: 100%;
}

.form-section-fields-container {
  @include flexbox;
  @include justify-content(column);
}

.form-section-fields-container__column {
  width: 50%;
  .form-group {
    margin-bottom: 0;
    // > label {
    //   display: none;
    // }
  }
}

.form-section-fields-container__column > div {
  @include flexbox;
  @include align-items(center);
  margin-bottom: $spacing-xs;
  label {
    text-align: right;
    width: 200px;
    padding-right: $spacing-s;
    @include flex-shrink(0);
  }
  label ~ div {
    width: 100%;
  }
  .Select {
    width: 100%;
  }
  .Select-menu-outer {
    z-index: 1000;
    background: #fff;
  }
  input[type='text'] {
    width: 100%;
  }
}

.chef-food-container {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(flex-start);
  > div:first-child {
    width: 30%;
    flex-shrink: 0;
    margin-right: $spacing-m;
  }
  > div:nth-child(2) {
    width: 100%;
  }
  .input-group-btn {
    @include flexbox;
    @include justify-content(flex-start);
    width: 100%;
    button {
      width: 100%;
      margin-bottom: $spacing-xs;
    }
    .input-group-btn:last-child > .btn {
      margin-left: 0;
    }
  }
  input {
    width: 100%;
    margin-bottom: $spacing-s;
    text-align: center;
  }
}

.items-container {
  background: #f4faff;
  width: 100%;
  height: 100%;
  border: 1px solid $gray200;
}

.input-group-btn:last-child > .btn {
  border-radius: 3px;
}

.input-group-btn,
.input-group-btn:last-child > .btn {
  width: 100%;
}

.input-group-btn {
  display: inline-block;
}

.section {
  margin-bottom: 0;
}

.approved-check {
  color: $darker-green;
}

.not-approved-x {
  color: $pink-red;
}

.b-100 {
  bottom: 100px;
}

.l-150 {
  left: 150px;
}

.custom-concept {
  background: #e65e5f;
  color: white;
  padding: 5px 0px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.standard-concept {
  background: #4da9be;
  color: white;
  padding: 5px 0px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.small-text {
  font-size: smaller;
}
