.user-modal-container {
  center {
    max-width: 100%;
  }
  .form-field-container {
    p {
      width: 190px;
    }
  }
}
