.alert-container {
  z-index: 10000;
  position: relative;
}

.alert-container__inner {
  @include transition(all, 0.5s);
  position: fixed;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  width: 400px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -100px;
  padding: $spacing-m;
  z-index: 1000;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.35);
  p {
    color: #fff;
    font-family: $semibold;
    position: relative;
    margin-left: 20px;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
  }
  @media screen and (max-width: 350px) {
    width: 280px;
  }
}

.hide-alert {
  animation: hideAlert normal forwards ease-in-out;
  animation-duration: 0.35s;
}

@keyframes hideAlert {
  to {
    top: -100px;
    opacity: 0;
  }
}

.alert-success {
  background: darken($dark-green, 15%);
  p {
    &:before {
      content: '';
      position: absolute;
      background: url('../images/icon-white-check.png') no-repeat;
      background-size: 20px;
      width: 20px;
      height: 30px;
      left: -25px;
    }
  }
}

.alert-fail {
  background: $orange;
  p {
    &:before {
      content: '';
      position: absolute;
      background: url('../images/error-icon.png') no-repeat;
      background-size: 20px;
      width: 20px;
      height: 30px;
      left: -25px;
    }
  }
}

.alert-info {
  background: $blue;
  p {
    color: #fff;
    &:before {
      content: '';
      position: absolute;
      background: url('../images/info-icon.png') no-repeat;
      background-size: 20px;
      width: 20px;
      height: 30px;
      left: -25px;
    }
  }
}

.alert-warning {
  background: #ffd552;
  p {
    color: $gray400;
    &:before {
      content: '';
      position: absolute;
      background: url('../images/warning.svg') no-repeat;
      background-size: 20px;
      width: 20px;
      height: 30px;
      left: -25px;
      top: -3px;
    }
  }
}

.close-flash {
  position: absolute;
  right: 20px;
  top: 7px;
  opacity: 0.6;
  &:before {
    content: '';
    position: absolute;
    background: url('../images/x-icon.png') no-repeat;
    background-size: 10px;
    width: 12px;
    height: 10px;
  }
}

.alert-modal {
  .modal-dialog {
    width: 500px;
  }
}
