.orderform-title {
  color: darken($gray400, 20%);
  font-size: $font-size-s;
  font-family: $semibold;
  padding: 0 $spacing-m;
  margin: $spacing-s 0;
}

.capacity-section-title {
  margin-bottom: 0;
}

.order-modal,
.edit-chef-modal {
  .add-button,
  .cancel-button {
    background: none;
    border: none;
    text-decoration: underline;
    color: $blue;
    text-transform: uppercase;
    font-size: $font-size-xxxs;
    font-family: $semibold;
    margin-left: $spacing-xs;
  }
  .cancel-button {
    color: $gray400;
  }
  .clear-form {
    position: absolute;
    right: 15px;
    top: 10px;
    background: none;
    border: none;
    font-size: $font-size-xxxs;
    font-family: $semibold;
    text-decoration: underline;
  }
  .order-items-column {
    width: 350px;
    min-width: 350px;
    .dropdown,
    input,
    select {
      width: 100%;
    }
    .spacing-top-small {
      margin-top: $spacing-xs;
    }
  }
  .disabled {
    select {
      background: lighten($gray100, 4%);
      border-color: lighten($gray200, 10%);
      cursor: not-allowed;
    }
  }
}

.dropdown--menu-items {
  select {
    background: #fff;
    border-radius: 3px;
    font-size: $font-size-xxs;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: $spacing-xxs $spacing-m;
  }
}

.menu-items-container {
  background: $light-blue;
  border: 1px solid $gray200;
  margin-top: $spacing-s;
  padding: $spacing-m;
  max-height: 600px;
  overflow-y: scroll;
}

.menu-item-groupname {
  font-family: $bold;
  font-size: $font-size-xxs;
  padding: $spacing-xxs 0;
  cursor: pointer;
  position: relative;
}

.side-arrow {
  margin-left: $spacing-xxs;
  &:before {
    content: '';
    bottom: 9px;
    opacity: 0.7;
    position: absolute;
    background: url('../images/circle-arrow.png') no-repeat;
    background-size: 13px;
    width: 13px;
    height: 13px;
  }
}

.down-arrow {
  margin-left: $spacing-xxs;
  &:before {
    content: '';
    bottom: 9px;
    opacity: 0.7;
    position: absolute;
    background: url('../images/circle-arrow.png') no-repeat;
    background-size: 13px;
    width: 13px;
    height: 13px;
    transform: rotate(90deg);
  }
}

.selectable-item {
  cursor: pointer;
  @include flexbox;
  margin-bottom: 5px;
}

.plus-sign {
  font-family: $bold;
  font-size: $font-size-xs;
  color: darken($darker-green, 10%);
  margin-right: $spacing-xxs;
}

.minus-sign {
  color: red;
  font-family: $bold;
  font-size: 13px;
  cursor: pointer;
}

.byo-container {
  @include flexbox;
  .byo-category {
    font-size: $font-size-xxxs;
    font-family: $bold;
    color: $blue;
    border-radius: 10px;
    margin-right: $spacing-xxs;
  }
  .byo-item {
    @include flexbox;
    @include align-items(center);
  }
}

.menu-item {
  padding-right: $spacing-xxs;
  font-size: $font-size-xxs;
}

.add-custom {
  margin-bottom: $spacing-xs;
  .menu-item {
    font-family: $semibold;
  }
}

.dietary-tag {
  font-size: 14px;
}

.textarea-with-collapse {
  display: inline-block;
  position: relative;
  margin-bottom: $spacing-m;
  textarea {
  }
  button {
    position: absolute;
    right: 0;
    bottom: -17px;
  }
}

.item-search {
  text-align: center;
  margin-top: $spacing-xs;
  padding: $spacing-xxs;
  // &::placeholder {
  //   color: lighten($gray300, 20%) !important;
  // }
}

.form-field-container {
  h2 {
    text-align: left;
  }
}

.menu-card-tooltip {
  //top: 10px;
  background: #2eb9f0;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 175px;
  border-radius: 5px;
  padding: 5px 5px 0px 5px;
  //left: 50%;
  margin-bottom: 15px;
  transform: translate(-20%, -119%);
  p {
    color: #fff;
    text-align: left;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    top: auto;
    bottom: -14px;
    transform: translate(200%, 70%);
    border-style: solid;
    border-width: 17.3px 10px 0px 10px;
    border-color: #2eb9f0 transparent transparent transparent;
  }
}

.menu-item-tooltip {
  background: #2eb9f0;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 175px;
  border-radius: 5px;
  padding: 5px 5px 0px 5px;
  margin-bottom: 15px;
  transform: translate(-5%, -90%);
  p {
    color: #fff;
    text-align: left;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    top: auto;
    bottom: -14px;
    transform: translate(200%, 70%);
    border-style: solid;
    border-width: 17.3px 10px 0px 10px;
    border-color: #2eb9f0 transparent transparent transparent;
  }
}

/***************** Menu Item Table styles ************/
.row-separator {
  padding-top: $spacing-s;
  border-top: 1px solid $gray200;
}

.menu-item-table {
  width: 100%;
}

.menu-item-chef {
  font-size: $font-size-xs;
  color: $blue;
  // border-bottom: 1px solid $gray200;
  padding-bottom: $spacing-xxs;
}

.menu-item-table__titles {
  font-family: $semibold;
  text-align: center;
  td:first-child {
    width: 24px;
  }
  td:nth-child(2) {
    width: 250px;
    text-align: left;
  }
}

.menu-item-table__input-fields {
  input {
    width: 90%;
    border: 1px solid $gray200;
    border-radius: 10px;
    padding: 3px $spacing-xxs;
    text-align: center;
  }
  td.item-total {
    input {
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }
  // input:focus {
  //   padding: 3px $spacing-xxs;
  //   border: 1px solid $gray200 !important;
  //   border-radius: 10px;
  //   box-shadow: none !important;
  // }
  .custom-field,
  .custom-field:focus {
    width: 100%;
    font-size: $font-size-xxs;
    background: $light-blue;
    border: 1px solid $gray200;
    border-radius: 4px;
    padding: $spacing-xxs;
    text-align: left;
    &::placeholder {
      color: lighten($gray300, 20%) !important;
    }
  }
}

.custom-dropdown {
  select {
    width: 100%;
    padding: $spacing-xxs;
    appearance: none;
    font-size: $font-size-xxs;
    background: $light-blue;
    border: 1px solid $gray200;
    border-radius: 4px;
    text-align: left;
  }
}

.servings-container {
  margin: $spacing-xxs 0;
  @include flexbox;
  @include justify-content(space-between);
  input {
    padding: $spacing-xxs;
    font-size: $font-size-xxs;
    background: $light-blue;
    border: 1px solid $gray200;
    border-radius: 4px;
    text-align: center;
    width: 100px;
  }
}

.collapsible-textarea,
.textarea-notes {
  font-size: $font-size-xxs;
  padding: $spacing-xxs;
  background: $light-blue;
  border: 1px solid $gray200;
  border-radius: 4px;
  text-align: left;
  width: 250px;
  height: 80px;
  margin-right: $spacing-xs;
  &::placeholder {
    color: lighten($gray300, 20%) !important;
  }
}

.textarea-notes {
  background: #fff;
}

.dietary-pref-selector {
  margin: $spacing-xxs 0;
  font-size: $font-size-xxxs;
  .Select-control {
    background: $light-blue;
  }
  .Select-placeholder {
    padding: $spacing-xxs;
  }
}

.total-section {
  margin-top: $spacing-xl;
  @include flexbox;
  @include flex-direction(column);
}

.total-section__inner {
  width: 400px;
  @include align-self(flex-end);
  input[type='checkbox'] {
    margin-left: $spacing-xs;
  }
  label {
    font-size: $font-size-xxxs;
    margin-left: $spacing-xxs;
  }
  input[type='text'] {
    width: 70px;
    text-align: right;
    border-radius: 3px;
    border: 1px solid $gray200;
  }
  select {
    padding: 3px;
    font-size: $font-size-xxxs;
    background: $gray100;
    border: 1px solid $gray200;
    margin-right: $spacing-xs;
    border-radius: 3px;
  }
}

.container-space-between {
  margin-bottom: $spacing-xxs;
  @include flexbox;
  @include justify-content(space-between);
}

.subtotal {
  font-family: $bold;
}

.total {
  border-top: 1px solid $gray200;
  margin-top: $spacing-xs;
  padding-top: $spacing-xxs;
  text-align: right;
  span {
    font-family: $bold;
    color: $darker-green;
  }
}

.tip-button {
  background: none;
  border: none;
  color: $blue;
  font-family: $bold;
}

.button-container {
  margin-top: $spacing-l;
  @include flexbox();
  @include justify-content(flex-end);
}

.save-order {
  background: $darker-green;
  border: none;
  color: #fff;
  font-family: $bold;
  padding: $spacing-xxs $spacing-l;
  border-radius: 30px;
}

.display-flex {
  display: flex;
}

.button-primary,
.button-secondary,
.button-alert,
.button-neutral {
  border: none;
  white-space: nowrap;
  color: #fff;
  font-family: $regular;
  padding: $spacing-xxs $spacing-l;
  border-radius: 30px;
  &:disabled {
    cursor: not-allowed;
    background: darken($gray100, 10%);
    border: 1px solid rgba(0, 0, 0, 0);
  }
}

.button-secondary {
  background: $orange;
  border: 1px solid $orange;
  transition: all 0.3s ease;
  &:hover {
    background: darken($orange, 10%);
    border: 1px solid darken($orange, 10%);
    &:disabled {
      cursor: not-allowed;
      background: darken($orange, 10%);
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }
}

.button-primary {
  background: $blue;
  border: 1px solid $blue;
  transition: all 0.3s ease;
  &:hover {
    background: darken($blue, 10%);
    border: 1px solid darken($blue, 10%);
    &:disabled {
      cursor: not-allowed;
      background: darken($gray100, 10%);
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }
}

.button-alert {
  background: $pink-red;
  border: 1px solid $pink-red;
  transition: all 0.3s ease;
  &:hover {
    background: darken($pink-red, 10%);
    border: 1px solid darken($pink-red, 10%);
  }
}

.button-neutral {
  border: 1px solid lighten($gray300, 20%);
  color: $gray300;
  transition: all 0.3s ease;
  &:hover {
    background: $gray100;
  }
}

.button-small {
  font-size: 12px;
  padding: 5px 8px;
  margin: 3px;
  white-space: nowrap;
}

.cancel-order {
  border: none;
  background: none;
  font-family: $bold;
  margin-left: $spacing-m;
}

/********** Remove bootstrap styles *****************/
.flatpickr.active {
  box-shadow: none;
}

.glyphicon {
  display: none;
}

.haswarning .flatpickr.active,
.has-success .flatpickr.active,
.flatpickr-input {
  //  border-color: $gray200 !important;
  //box-shadow: none !important;
}

.noCalendar {
  width: 220px;
}

.flatpickr-time input {
  width: 100%;
  background: #fff;
}

.margin-details {
  background: $gray100;
  padding: 10px;
  border: 1px solid $gray200;
}

.portion-calculator {
  border: 1px solid $neon-blue;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  background: $light-blue;
  text-align: center;
}

.portion-calculator-title {
  font-family: $bold;
  color: darken($neon-blue, 10%);
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    margin-right: 5px;
    transform: rotate(-10deg);
  }
}

.calculator-tip {
  font-size: 14px;
}

.portion-calculator-input {
  font-family: $regular;
}
