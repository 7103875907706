.slack-auth-data-modal {
  .loader-container {
    display: flex;
    justify-content: center;
  }
  .heading-container {
    margin-bottom: 20px;
    p {
      font-family: 'lightitalic';
      color: $gray400;
    }

    .heading-text {
      margin-bottom: 20px;
    }
  }

  .options-section {
    .no-slack-auth-data-options {
      font-family: 'regular';
      border: solid 1px $gray100;
      padding: 10px;
      background-color: $yellow;
    }

    .options-container {
      border: solid 1px $gray100;
      min-height: 200px;
      max-height: 250px;
      overflow-y: auto;
      .slack-auth-data-option {
        text-align: left;
        font-family: 'regular';
        border: none;
        border-bottom: solid 1px $gray100;
        margin: none;
        padding: 10px 5px;
        display: block;
        width: 100%;
        background-color: inherit;
        &.selected {
          background-color: $yellow;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .link-text {
      margin-right: 20px;
    }
  }
}
