.subscriber-row {
  .show-subscriber-button {
    display: none;
  }
  &:hover {
    .show-subscriber-button {
      display: block;
    }
  }
}

.show-subscriber-button {
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}

.subscription-card {
  .subscription-edit-button {
    display: none;
  }
  &:hover {
    .subscription-edit-button {
      display: block;
    }
  }
}

.member-info-row {
  .subscription-edit-button {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
  }
  .subscription-close-edit-button {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, -25%);
  }
  &:hover {
    .subscription-edit-button {
      display: block;
    }
  }
}
