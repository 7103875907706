.search-panel {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 20px;
  background-color: white;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.results-info {
  width: 20%;

  p {
    font-size: 1rem;
    font-weight: normal;
  }
}

.pagination-controls {
  width: 10%;
  display: flex;
  align-items: center;
}

.pagination-button {
  border: 1px solid lightgray;
  border-radius: 5px;
  display: inline-flex;
  padding: 5px;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    cursor: pointer;
  }
}

.pagination-text {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.color-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-left: 10px;

  &.lightblue {
    background-color: lightblue;
  }

  &.orange {
    background-color: orange;
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-weight: bold;
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 6px;
      padding: 8px;
      border-left: 4px solid #ddd;
      background-color: #f9f9f9;
      border-radius: 4px;

      strong {
        font-weight: bold;
      }

      &.notes-item {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }
  }

  .color-indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: white;
    margin-left: 5px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;

    &.orange {
      background-color: #ff9800;
    }
    &.lightblue {
      background-color: lightblue;
    }
  }
}

.empty-state {
  text-align: center;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.01);
}

.verification-cell {
  text-align: center;
  vertical-align: center;
}

.icon-centered {
  margin-left: 45%;
}

.row-verified-incomplete {
  background-color: orange;
}

.row-child-item {
  background-color: lightblue;
}

// .badge-cuisine {
//   // Add your badge styling here
// }

.search-button {
  background-color: #0288d1;
}

.child-items-toggle {
  &.expanded {
    width: 25%;
  }

  &.collapsed {
    width: 10%;
  }
}

.checkbox-container {
  &.expanded {
    width: 35%;
  }

  &.collapsed {
    width: 100%;
  }
}
