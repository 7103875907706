.homepage-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.charts-container {
  width: 100%;
  display: flex;
  padding: 0 30px;
  align-items: flex-start;
  flex-direction: column;
}

.boxes {
  box-shadow: 0px 3px 11px 1px rgba(0, 0, 0, 0.22);
  width: 450px;
  height: 250px;
  margin: 20px;
  align-items: center;
  border-radius: 8px;
}

.boxes-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 80px;
  text-align: center;
}

.box_names {
  font-family: Bold;
  font-size: 25px;
  color: white;
  text-decoration: none;
  padding-top: 25%;
}

.charts-container__inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  flex-wrap: wrap;
}

.charts-container {
  .date-filters {
    width: 100%;
    p {
      font-size: 12px;
      text-transform: uppercase;
      font-family: $light;
    }
  }
  .date-filters__inner .form-group {
    width: 140px;
    min-width: 140px;
  }
  input.flatpickr {
    font-size: 12px;
    background: #fff !important;
    padding: 5px 10px;
    height: 28px;
  }
}

.hungry-tutorials {
  margin-top: 30px;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 11px 1px rgba(0, 0, 0, 0.22);
}

.hungry-tutorials-title {
  font-family: $bold;
  font-size: $font-size-s;
  color: $gray400;
  margin-bottom: 20px;
}

.hungry-tutorials-container {
  -webkit-column-count: 3; /* Old Chrome, Safari and Opera */
  -moz-column-count: 3; /* Old Firefox */
  column-count: 3;
}

.hungry-tutorial-link {
  display: block;
  color: $blue;
  font-size: 17px;
  padding: 5px 0;
  margin-bottom: 10px;
}

.single-chart {
  margin-top: 30px;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  width: 48%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 11px 1px rgba(0, 0, 0, 0.22);
  height: 400px;
}

.single-chart__inner {
  height: 240px;
}

.chef-chart {
  .single-chart__inner {
    height: 290px;
  }
}

.chart-title-container {
  display: flex;
  justify-content: space-between;
}

.chart-title {
  font-family: $bold;
  font-size: $font-size-s;
  color: $gray400;
  margin-bottom: 3px;
}

.chef-chart-title {
  margin-bottom: 20px;
}

.chart-subtitle {
  font-family: $regular;
  font-size: 17px;
  color: $gray400;
}

.filter-container {
  position: absolute;
  display: flex;
  bottom: 15px;
  right: 20px;
  .filter {
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      content: '▼';
      pointer-events: none;
      position: absolute;
      right: 5px;
      top: 7px;
      color: lighten($gray400, 25%);
      font-size: 10px;
    }
  }
}

.filter-title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  color: $gray400;
  font-family: $light;
  margin-right: 4px;
}

.filter-dropdown {
  appearance: none;
  background: none;
  border: 1px solid $gray200;
  color: $gray400;
  font-family: $regular;
  padding: 5px 10px;
  padding-right: 15px;
  font-size: 12px;
  border-radius: 4px;
}
