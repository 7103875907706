.tabs {
  margin: auto;
  text-align: left;
  max-width: 1700px;
}

.tabs-container {
  max-width: 1750px;
  width: 100%;
}

.tab-content-column-container {
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}

.tab-content-column__single {
  width: 49%;
}

.details-tab {
  br {
    display: none;
  }
  .nav-tabs {
    margin-bottom: $spacing-s;
  }
}

.tabs-container__inner {
  text-align: left;
}

.list-group {
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.22);
}

.list-group-item {
  > div {
    @include flexbox;
    @include justify-content(space-between);
  }
  .bio-container {
    @include flex-direction(column);
    span:first-child {
      margin-bottom: $spacing-s;
    }
  }
  span:first-child {
    padding-right: $spacing-m;
  }
  span {
    //display: block;
    float: none !important;
  }
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  font-family: $bold;
  background: none;
  border: none;
  color: $gray400;
  font-size: 17px;
  border-bottom: 4px solid $orange;
}

.nav-tabs > li > a {
  padding: 5px 0;
  margin-right: 20px;
  color: darken($gray200, 10%);
  font-size: 17px;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
}

.nav-tabs {
  border-bottom: none;
}
