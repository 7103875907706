.dashboard-section-title {
  font-size: 16px;
  color: $gray400;
  margin-bottom: 0;
  white-space: nowrap;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.dashboard-card {
  position: relative;
  overflow: hidden;
  width: 260px;
  margin: 15px;
  margin-left: 0;
  margin-top: 0;
  cursor: pointer;
  &:hover {
    .text-overlay {
      opacity: 1;
    }
    .opacity-overlay {
      bottom: 0;
    }
  }
}

.dashboard-card.no-image {
  .text-overlay {
    opacity: 1;
    p {
      color: $gray400;
    }
  }
  .card-title,
  .order-total {
    color: $gray400;
  }
  .opacity-overlay {
    opacity: 1;
    background: darken($gray100, 2%);
    bottom: 0;
  }
}

.card-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  .sales-dashboard-image {
    width: 160%;
  }
}

.gradient-overlay {
  transition: all 0.3s;
  position: absolute;
  width: 100%;
  height: 200px;
  opacity: 0;
  z-index: 2;
  @include orange-gradient;
}

.text-overlay {
  padding: 15px;
  position: relative;
  z-index: 3;
  opacity: 0;
  transition: all 0.3s;
  p {
    color: #fff;
  }
}

.card-details {
  p {
    margin-bottom: 3px;
  }
}

.chef-name {
  color: #fff;
  text-align: center;
  font-size: $font-size-m;
  font-family: $bold;
  margin-top: 30px;
}

.sales-page {
  .card-container {
    min-width: 1200px;
    @media screen and (max-width: 1400px) {
      min-width: 900px;
    }
    @media screen and (max-width: 1150px) {
      min-width: auto;
    }
  }
}

.card-title,
.order-total {
  z-index: 2;
  position: relative;
  color: #fff;
  font-family: $bold;
  text-align: center;
  font-size: $font-size-s;
}

.card-title {
  max-width: 250px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 12px;
}

.chef-card-title {
  margin-top: 55px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}

.opacity-overlay {
  transition: all 0.3s;
  position: absolute;
  width: 100%;
  height: 200px;
  opacity: 0.65;
  z-index: 2;
  background: black;
  bottom: -65%;
}

.image-substitute {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $gray200;
}
