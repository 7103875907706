.trash-icon {
  width: 30px;
  height: 35px;
  padding: 7px;
  cursor: pointer;
}

.button-container {
  margin-bottom: $spacing-m;
  @include flexbox;
  button,
  a {
    &:first-child {
      margin-right: $spacing-s;
    }
  }
}

.add-button {
  background: $blue;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: darken($blue, 10%);
  }
}

.add-new-button {
  border-radius: 5px;
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: $font-size-xxs;
  padding: $spacing-s $spacing-s;
  background: $orange;
  @include transition(background, 0.2s);
  background-position: 0px;
  display: inline-block;
  margin-bottom: $spacing-s;
  text-transform: uppercase;
  font-family: $semibold;
  -webkit-box-shadow: 0px 7px 23px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 23px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 23px -4px rgba(0, 0, 0, 0.2);
  &:focus,
  &:hover {
    color: #fff;
    text-decoration: none;
    background: darken($orange, 5%);
  }
  span {
    font-family: $extrabold;
  }
}
