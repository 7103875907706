.proposal-center-container {
  @include flexbox;
  @include flex-direction(column);
  @include flex-center-horizontal-vertical;
}

.proposal-cards-container {
  max-width: 900px;
  width: 100%;
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);

  .menu-card {
    width: 48%;
    border: none;
    .menu-card-header {
      background: #ffc7c1;
      color: $violet;
    }
    .chef-info-container {
      @include flexbox;
      @include align-items(center);
    }
    .chef-details {
      text-align: center;
      width: 100%;
      margin-left: 60px;
      .chef-name {
        font-family: $extrabold;
        font-size: $font-size-m;
      }
      .chef-rating {
        @include justify-content(center);
      }
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 34px -1px rgba(0, 0, 0, 0.26);
      -moz-box-shadow: 0px 0px 34px -1px rgba(0, 0, 0, 0.26);
      box-shadow: 0px 0px 34px -1px rgba(0, 0, 0, 0.26);
      top: 0px;
      cursor: auto;
    }
  }
  .divider {
    padding-top: $spacing-xs;
    margin: $spacing-xs 0;
    border-bottom: 1px solid lighten($gray400, 10%);
    width: 100%;
  }
  .price-container {
    @include flexbox;
    @include justify-content(space-between);
  }
  .total {
    text-align: right;
    span {
      font-family: $bold;
      font-size: 18px;
      color: $darker-green;
    }
  }
  textarea {
    width: 100%;
    border-radius: 4px;
    margin-top: $spacing-s;
    padding: $spacing-xs;
    box-sizing: border-box;
    font-size: $font-size-xxs;
    border: 1px solid #b8b8b8;
    height: 100px;
  }
  .promo-input,
  .tip-input {
    border-radius: 3px;
    border: 1px solid #b8b8b8;
    width: 80px;
    font-size: $font-size-xxs;
    padding: $spacing-xxs;
    color: $gray400;
  }
  .tip-input {
    width: 40px;
  }
  .tip-field {
    span {
      color: $light-purple;
      font-family: $bold;
      margin-left: $spacing-xs;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.proposal-time-container {
  margin-top: $spacing-l;
  margin-bottom: $spacing-l;
  text-align: center;
  h2 {
    font-family: $regular;
    @include gradient-text;
  }
  p {
    font-family: $regular;
    font-size: $font-size-s;
  }
}

.proposal-textarea {
  font-family: $regular;
  color: $gray400;
  border: 1px solid $gray200;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  font-size: 18px;
}

.proposal-view {
  background: $blue;
  border-radius: 4px;
  width: 70px;
  height: 70px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.4;
  img {
    width: 35px;
  }
  p {
    font-size: 14px;
    color: #fff;
    font-family: $bold;
    margin-top: auto;
  }
}

.proposal-view.active {
  opacity: 1;
}

.payment-processing {
  color: $blue;
  font-family: $bold;
  font-size: 20px;
}

.payment-proecssing.popup-button {
  width: 200px;
}

.payment-processing-small {
  color: $blue;
  font-family: $bold;
  font-size: 17px;
}

.w-52 {
  width: 52px;
}

.content-table-container {
  padding: $spacing-xs;
  background: $gray100;
}

.content-table {
  font-size: $font-size-xs;
  width: 100%;
  .menu-price {
    font-size: 12px;
  }
  th {
    padding-top: $spacing-s;
  }
  tr th:first-child div {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 21px;
    background: $gray400;
    border-radius: 50px;
    color: #fff;
    font-family: $bold;
  }
  tr th:first-child {
    padding-right: $spacing-xxs;
  }
  tr th:nth-child(2) {
    text-align: left;
    font-size: 14px;
  }
  tr th:nth-child(3),
  tr th:nth-child(4) {
    font-family: $semibold;
    font-size: 13px;
  }
  tr th:nth-child(4) {
    text-align: right;
  }
  .proposal-menu-item {
    td {
      padding-top: $spacing-xs;
    }
    td:nth-child(3) {
      text-align: center;
      input {
        width: 20px;
        height: 20px;
        border: 1px solid #f68e56;
        color: $gray400;
        background: none;
        text-align: center;
        font-size: $font-size-xxs;
      }
    }
    td:nth-child(4) {
      text-align: right;
    }
  }
}

.tip {
  p:first-child {
    font-family: $bold;
  }
  @include flexbox;
  @include flex-direction(column);
}

.tip-total {
  align-self: flex-end;
}

.tip-box-container {
  margin-top: $spacing-xs;
  @include flexbox;
  @include flex-direction(row);
}

.tip-box {
  position: relative;
  width: 55px;
  margin-right: $spacing-xs;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }
  label {
    display: block;
    position: relative;
    width: 55px;
    height: 45px;
    background: #fff;
    text-align: center;
    border-radius: 5px;
    border: 1px solid $gray200;
    font-family: $bold;
    -webkit-box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    padding-top: 5px;
    box-sizing: border-box;
  }
  input[type='radio']:checked ~ label {
    background: $darker-green;
    color: #fff;
    border: 1px solid darken($darker-green, 10%);
  }
  input[type='radio']:checked ~ label ~ .total-tip {
    color: #fff;
  }
}

.tip-box-container .tip-box:nth-child(4) label {
  padding: 0;
  line-height: 45px;
}

.total-tip {
  position: absolute;
  font-size: 12px;
  bottom: 5px;
  text-align: center;
  width: 100%;
  pointer-events: none;
}

.proposal-buttons {
  @include flexbox;
  @include justify-content(space-between);
  margin-top: $spacing-xl;
  button {
    -webkit-appearance: none;
    border: none;
    width: 48%;
    border-radius: 50px;
    font-size: $font-size-xxs;
    padding: $spacing-xs;
    font-family: $semibold;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
  button:first-child {
    background: $darker-green;
    &:hover {
      background: darken($darker-green, 10%);
    }
  }
  button:last-child {
    background: $light-purple;
    &:hover {
      background: darken($light-purple, 10%);
    }
  }
}

.proposal-dropdown {
  .Select-control {
    border-radius: 4px;
    border: 1px solid darken($gray200, 10%);
    font-size: $font-size-xxs;
    -webkit-box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 0px 24px -3px rgba(0, 0, 0, 0.22);
    width: 60%;
  }
  .Select-menu-outer {
    border-radius: 0 0 4px 4px;
    border: 1px solid darken($gray200, 10%);
    border-top: 0px;
  }
}

.tip-input {
  @include flexbox;
  @include flex-direction(row);
  @include align-items(center);
  font-size: $font-size-xs;
  span {
    padding-top: 2px;
  }
  input[type='number'] {
    font-size: $font-size-xs;
    border: none;
    background: none;
    width: 50px;
    padding: 5px 0;
    padding-left: 1px;
    color: $gray400;
  }
}

.proposal-min-eight {
  top: -93px;
  position: absolute;
  background: $blue;
  z-index: 3;
  width: 200px;
  border-radius: 5px;
  padding: $spacing-m;
  left: 50%;
  transform: translate(-50%, 0);
  p {
    color: #fff;
    font-family: $regular;
  }
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: auto;
    bottom: -14px;
    transform: translate(-50%, 0);
    border-style: solid;
    border-width: 17.3px 10px 0 10px;
    border-color: $blue transparent transparent transparent;
  }
}

.tip-banner {
  border: none;
  background: $light-blue;
  color: $blue;
  font-family: $regular;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.tip-info {
  font-family: $bold;
  background: $blue;
  color: $light-blue;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.calendar-hover {
  padding-left: 20px;
  margin-left: 150px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.calendar-hover__inner {
  background: white;
  width: 500px;
  max-height: 600px;
  overflow-y: scroll;
  box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
}

.toppings {
  font-family: $regular;
  color: $gray400;
}

.quick-view {
  background: $gray400;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0;
}

.small-calendar-title {
  font-family: $extrabold;
  text-align: center;
  color: $gray400;
  font-size: 25px;
}

.small-calendar {
  cursor: pointer;
}

.small-calendar,
.big-calendar {
  border-collapse: collapse;
  border-style: hidden;
  table-layout: fixed;
  width: 100%;
  border: 1px solid $gray300;
  border-radius: 4px;
  background: #eeeeee;
  -webkit-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  .calendar-date {
    padding: 5px;
  }
}

.calendar-tr {
  th {
    text-align: right;
    text-transform: uppercase;
    font-family: $bold;
    color: $gray400;
    border-right: 1px solid darken($gray200, 10%);
    border-bottom: 1px solid $gray300;
    padding: 5px;
    padding-top: 10px;
  }
}

.td-small,
.td-large {
  font-family: $bold;
  color: $gray300;
  text-align: right;
  width: 25%;
  height: 60px;
  border-top: 1px solid darken($gray200, 10%);
  border-right: 1px solid darken($gray200, 10%);
}

.td-large {
  padding: 8px;
  height: 120px;
}

.big-calendar-title {
  font-size: 30px;
  width: 240px;
  font-family: $bold;
  text-align: center;
  color: $gray400;
}

.big-calendar {
  table-layout: fixed;
  width: 100%;
}

.small-calendar-proposal,
.small-calendar-order {
  font-size: 12px;
  background: darken($emerald, 5%);
  color: #fff;
  white-space: nowrap;
  padding: 2px 0;
  text-align: center;
}

.small-calendar-proposal {
  @include orange-gradient;
}

.large-calendar-proposal,
.large-calendar-order {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  .calendar-time-copy {
    color: #fff;
    font-family: $bold;
    font-size: 14px;
  }
}

.large-calendar-proposal {
  @include orange-gradient;
}

.calendar-date-hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  -webkit-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
}

.calendar-dropdown {
  select {
    background: #fff;
    font-size: 20px;
    padding: 15px 20px;
    border-radius: 25px;
    font-family: $regular;
    color: $gray400;
    border: 1px solid $gray200;
    -webkit-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 5px 22px -13px rgba(0, 0, 0, 0.6);
  }
}

.calendar-edit {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  width: 100%;
  z-index: 3;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $gray300;
}

.month-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-arrow {
  color: $gray400;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
}

.calendar-legend {
  font-family: $regular;
  color: $gray400;
  font-size: 18px;
}

.approved-menus,
.not-approved-menus,
.complete-menus {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.approved-menus {
  background: darken($emerald, 5%);
}

.not-approved-menus {
  @include orange-gradient;
}

.complete-menus {
  background: darken($emerald, 5%);
}

.legend-headcount,
.legend-breakfast,
.legend-lunch {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  font-family: $extrabold;
}

.legend-headcount {
  background: darken($dark-yellow, 20%);
  color: #fff;
}

.legend-breakfast {
  border: 1px solid $gray200;
  color: darken($dark-yellow, 20%);
}

.legend-lunch {
  border: 1px solid $gray200;
  color: $blue;
}

.calendar-back {
  color: $gray400;
  font-family: $regular;
  cursor: pointer;
}

.calendar-plus {
  background: $emerald;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 17px;
  font-family: $extrabold;
  text-align: center;
  line-height: 22px;
  border-radius: 10px;
  margin-right: 10px;
}

.calendar-modal {
  width: 800px;
  margin: 30px 10px;
  background: #fff;
  box-sizing: border-box;
}

.close-modal {
  font-family: $bold;
  color: $gray400;
  font-size: 25px;
  padding: 10px;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.calendar-popup {
  color: $gray400;
  padding: 10px 15px;
  border-radius: 4px;
  table {
    font-family: $regular;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  th {
    font-family: $bold;
    padding-bottom: 3px;
    border-bottom: 1px solid $violet;
    color: $violet;
  }
}

.popup-name {
  font-family: $bold;
  text-align: left;
}

.popup-des {
  font-family: $regular;
  text-align: left;
}

.popup-chef-details {
  text-align: left;
  font-family: $regular;
  font-size: 17px;
}

.popup-chef-name {
  font-size: 18px;
  font-family: $bold;
}

.popup-input {
  font-family: $regular;
  font-size: 16px;
  color: $gray400;
  width: 50px;
  padding: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  text-align: center;
  background: $light-blue;
}

.total-divider {
  padding-top: 10px;
  border-top: 1px solid $gray200;
}

.calendar-legend-hc {
  display: flex;
  font-family: $regular;
  color: $gray400;
  font-size: 18px;
  align-items: center;
}

.hc-bubble {
  width: 25px;
  height: 25px;
  background: #ffd743;
  border-radius: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
}

.cancel-order {
  font-family: $regular;
  text-decoration: underline;
  color: $violet;
  cursor: pointer;
}

.calendar-chef-image {
  width: 150px;
}

.close-calendar-modal {
  color: #fff;
  font-family: $bold;
  padding: 4px;
  padding-right: 10px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}

.calendar-modal-label {
  text-transform: uppercase;
  color: $gray300;
  font-size: 14px;
  font-family: $bold;
  margin-bottom: 3px;
}

.calendar-modal-input,
.calendar-modal-select {
  font-family: $regular;
  font-size: 16px;
  color: $gray400;
  padding: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background: $light-blue;
}

.calendar-modal-select {
  width: 100%;
}

.calendar-modal-time input {
  font-family: $regular;
  font-size: 16px;
  color: $gray400;
  padding: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  text-align: left;
  background: $light-blue;
  width: 100px;
}

.calendar-modal-input.w-100 {
  width: 100px;
}

.calendar-modal-input.w-200 {
  width: 200px;
}

.save-link,
.cancel-link {
  cursor: pointer;
  color: $blue;
  text-decoration: underline;
  font-family: $regular;
}

.cancel-link {
  color: $violet;
}

.approved-menu {
  color: $blue;
  font-family: $bold;
  font-size: 18px;
}

.calendar-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  margin: auto;
}
