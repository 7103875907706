.flatpickr-calendar {
  z-index: 1000;
}
.flatpickr-month {
  height: 45px;
}
.flatpickr-wrapper {
  width: 100%;
}
.flatpickr:hover {
  cursor: text;
}
.flatpickr.active {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.time-field {
  background: #f8f8f8;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  font-size: 1.125rem;
  font-family: $regular;
  color: $gray400;
  padding: 0.5rem;
}

.stats-calendar {
  border-radius: 50px;
  font-family: $regular;
  font-size: 16px;
  padding: 8px 15px;
  text-align: center;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
