.progressbar-container {
  .progressbar-single:nth-child(n + 2) {
    margin-top: 5px;
  }
}

.progressbar-single {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .progress-captain {
    font-family: $bold;
    margin-right: 5px;
  }
}

.progressbar {
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: 250px;
  height: 10px;
  border-radius: 10px;
  background: #bec0c3;
  .progress:first-child {
    border-radius: 10px 0 0 10px;
  }
  .progress:last-child {
    border-radius: 0 10px 10px 0;
  }
  .progress.on-time:first-child {
    &:before {
      border-radius: 10px 0 0 10px;
    }
  }
  .progress.late:first-child {
    &:before {
      border-radius: 10px 0 0 10px;
    }
  }
  .progress.on-time:last-child {
    &:before {
      border-radius: 0 10px 10px 0;
    }
  }
  .progress.late:last-child {
    &:before {
      border-radius: 0 10px 10px 0;
    }
  }
}

.progress {
  border-radius: 0;
  margin-bottom: 0;
  overflow: visible;
  text-align: right;
  font-size: 6px;
  height: 10px;
  color: #fff;
  line-height: 9px;
  position: relative;
  z-index: 1;
  background: #bec0c3;
  display: flex;
  justify-content: flex-end;
  &:before {
    content: '';
    transition: all 0.2s ease-in-out;
    z-index: -1;
    width: 0;
    left: 0;
    position: absolute;
    height: 10px;
  }
}

.progress-point {
  width: 20px;
  color: #fff;
  &.discrepency {
    color: #af0000;
  }
}

.discrepency {
  color: $violet;
}

.progress.on-time {
  &:before {
    content: '';
    width: 100%;
    background: #4ebf53;
    position: absolute;
    height: 10px;
  }
}

.progress.late {
  &:before {
    content: '';
    width: 100%;
    background: lighten($violet, 20%);
    position: absolute;
    height: 10px;
  }
}

.progress-tooltip {
  position: absolute;
  background: #fff;
  font-size: 14px;
  color: $dark500;
  padding: 5px;
  z-index: 3;
  top: -70px;
  border-radius: 4px;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.22);
  border: 1px solid #bec0c3;
  right: -20px;
  font-family: $regular;
  p:first-child {
    font-family: $bold;
    text-transform: capitalize;
  }
  p {
    line-height: 1.2;
    white-space: nowrap;
    text-align: left;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 18px;
    bottom: -8px;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
  }
  &.small {
    top: -53px;
  }
  &.large {
    top: -87px;
  }
}

.progress-legend-late,
.progress-legend-early,
.progress-legend-not-started {
  width: 30px;
  height: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
}

.progress-legend-not-started {
  background: #bec0c3;
}

.progress-legend-early {
  background: #4ebf53;
}

.progress-legend-late {
  background: lighten($violet, 20%);
}

.legend-point {
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  color: #af0000;
  font-size: 10px;
}
