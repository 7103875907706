.partners-container {
  background: #fff;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.22);
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  max-width: 1000px;
}

.partners-table {
  border-collapse: separate;
  border-spacing: 0 20px;
  td {
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 20px;
  }
}
