.sidebar-page {
  display: flex;
  width: 100%;
}

.sidebar-body {
  width: 100%;
  margin-left: 230px;
  margin-bottom: 50px;
  padding: 20px;
}

.sidebar-body-inner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-body-label {
  color: $gray400;
  font-family: $bold;
  text-transform: uppercase;
  font-size: 13px;
}

.sidebar-body {
  .flatpickr-calendar.inline {
    z-index: 0;
  }
  .flatpickr-calendar,
  .flatpickr-innerContainer,
  .flatpickr-rContainer,
  .flatpickr-days {
    width: 100%;
  }
  .flatpickr-calendar {
    box-shadow: none;
  }
  .flatpickr-day.highlighted {
    background: $blue100;
    opacity: 1;
    border-radius: 4px;
  }
  .flatpickr-day.selected,
  .flatpickr-day.today {
    border-radius: 4px;
  }
  .flatpickr-day.selected {
    background: $orange;
  }
  .flatpickr-month {
    height: 35px;
  }
  .flatpickr-day {
    height: 30px;
    line-height: 30px;
  }
}

.sidebar-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-family: $bold;
    color: $gray400;
    padding: 5px 10px;
    background: #eff1f6;
  }
  td {
    color: $gray400;
    vertical-align: middle;
    padding: 5px 10px;
    font-family: $regular;
  }
}

.link-text {
  color: $blue300;
  text-decoration: underline;
  text-transform: uppercase;
  font-family: $bold;
  white-space: nowrap;
}

.selected-zip {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: $blue100;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #f89eac;
  }
}

.inactive-role,
.active-role {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: darkgray;
  font-size: 12px;
  font-weight: 900;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.active-role {
  background: #00acc1;
}

.panel-legend {
  display: flex;
  margin-bottom: 5px;
  span {
    width: 15px;
    height: 15px;
    border-radius: 30px;
    display: inline-block;
  }
  p {
    font-family: $regular;
    font-size: 14px;
    margin-left: 10px;
  }
}

.sidebar-body-panel {
  display: inline-flex;
  flex-direction: column;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 25px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.sidebar-body-heading {
  color: $orange;
  font-family: $extrabold;
  text-transform: uppercase;
}

.sidebar {
  width: 230px;
  height: 100%;
  z-index: 100;
  @include orange-gradient-vertical;
  position: fixed;
  top: 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: $bold;
  font-size: 18px;
  overflow-y: auto;
  h2 {
    color: #fff;
    font-family: $extrabold;
    padding: 30px 20px 10px 20px;
    background: $gray400;
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid white;
  }
  a {
    padding: 10px;
    padding-left: 15px;
    border-radius: 100px 0 0 100px;
    margin-left: 20px;
    margin-bottom: 10px;
    color: #fff;
    display: inline-block;
    width: 100%;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
  .active {
    background: #dfe4ea;
  }
}

.sidebar-scroll {
  overflow-y: auto;
}

.sidebar-item,
.sidebar-item-selected {
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-family: $regular;
  font-size: 16px;
  width: 100%;
  margin: 15px 0;
  word-break: break-word;
}

.sidebar-item-selected {
  font-family: $extrabold;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 20px 15px 0;
    border-color: transparent #eff1f6 transparent transparent;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.panel-input {
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    color: $gray400;
    font-family: $bold;
    text-transform: uppercase;
    font-size: 13px;
  }
  input,
  select,
  textarea {
    color: $gray400;
    width: 100%;
    background: #fafbff;
    font-family: $regular;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.4;
  }
  select {
    padding: 7px 5px;
  }
  textarea {
    height: 50px;
  }
}

.panel-tab,
.panel-tab-active {
  cursor: pointer;
  padding: 10px;
  color: #79899a;
}

.panel-tab {
  border-bottom: 1px solid gray;
}

.panel-tab-active {
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid white;
  color: $gray400;
  font-family: $bold;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.primary-button {
  background: $blue200;
  color: #fff;
  white-space: nowrap;
  font-family: $bold;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 30px;
  transition: 0.2s ease-in-out all;
  &:hover {
    background: $blue300;
    color: #fff;
    text-decoration: none;
  }
}

.group-menu-container {
  border: 1px solid #acacac;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.group-menu-container.pink {
  background-color: $pink;
}

.group-menu-name {
  font-family: $regular;
  color: $gray400;
  cursor: pointer;
}

.group-menu-items {
  margin-top: 10px;
  color: $gray400;
}

.group-menu-items {
  font-family: $bold;
  text-transform: uppercase;
}

.group-menu-item {
  font-family: $regular;
  text-transform: none;
  font-size: 13px;
}

.date-clear {
  position: absolute;
  right: 10px;
  top: 25px;
}

/***** OLD STUFF ******/

a.active {
  color: orange;
}

.nav li a {
  padding: 0;
}

.sidebar-button {
  cursor: pointer;
  background: $orange;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: absolute;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  border-bottom: 2px solid darken($orange, 30%);
  div {
    width: 20px;
    height: 3px;
    border-radius: 3px;
    background: white;
    margin: auto;
    position: relative;
    &:after,
    &:before {
      content: '';
      width: 20px;
      position: absolute;
      height: 3px;
      border-radius: 3px;
      background: white;
      margin: auto;
      margin-top: 7px;
    }
    &:before {
      margin-top: -7px;
    }
  }
}

.button-inactive {
  background: $gray200;
  border-bottom: 0;
}

.sidebar-container {
  margin-left: 0;
  @include transition(margin-left, 0.5s);
  z-index: 0;
  position: relative;
  //@include orange-gradient;;
  width: 200px;
  min-width: 200px;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding-top: $spacing-l;
}

.sidebar-opener {
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 100;
  position: absolute;
  pointer-events: none;
}

.sidebar-opener-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.sidebar-container .nav {
  margin: 0;
}

.sidebar-container .nav:last-child {
  //border-bottom: 1px solid #fff;
}

.sidebar-container .nav:first-child {
}

.sidebar-container .nav-item {
  width: 100%;
  font-size: 16px;
}

.sidebar-container .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar-container .nav-link {
  border-radius: 0;
}

.sidebar-container li a {
  letter-spacing: 0.08em;
  color: #fff;
  font-family: $regular;
  padding: $spacing-s $spacing-l;
}

.sidebar-container li a {
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0);
    color: $gray400;
  }
}

.sidebar-container li .active-link {
  background: #fff;
  color: $gray400;
  width: 205px;
  position: relative;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.2);
  &:hover,
  &:active,
  &:focus {
    background: #fff;
  }
  &:before {
    opacity: 0;
    @include transition(opacity, 0.5s);
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: -5px;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: $gray300 transparent transparent transparent;
  }
}

.hide-sidebar {
  margin-left: -160px;
  li .active-link {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      opacity: 0.7;
      background: url('../images/burger-icon.png') no-repeat;
      background-size: 30px;
      width: 30px;
      height: 30px;
      right: 8px;
    }
  }
}

/*  React autosuggest */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;
  border-top-left-radius: 0em;
  border-bottom-left-radius: 0em;
}

.has-success .react-autosuggest__input:focus {
  border-color: rgba(0, 176, 0, 1) !important;
  box-shadow: none;
  outline: 0 none !important;
}

.has-warning .react-autosuggest__input:focus {
  border-color: rgba(251, 176, 87, 1) !important;
  box-shadow: none;
  outline: 0 none !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 44px;
  width: 100%;
  text-align: left;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 500;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px dashed #efefef;
}
.react-autosuggest__suggestion:last-child {
  border-bottom: none;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #fafafa;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

/* Flatpickr */

.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 315px;
  box-sizing: border-box;
  transition: top cubic-bezier(0, 1, 0.5, 1) 100ms;
  z-index: 100;
  background: #fff;
  box-shadow:
    1px 0 0 #e6e6e6,
    -1px 0 0 #e6e6e6,
    0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  max-height: 350px;
}
.flatpickr-calendar.open {
  display: inline-block;
  -webkit-animation: flatpickrFadeInDown 300ms cubic-bezier(0, 1, 0.5, 1);
  animation: flatpickrFadeInDown 300ms cubic-bezier(0, 1, 0.5, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
}
.flatpickr-calendar.hasWeeks {
  width: auto;
}
.flatpickr-current-month.slideLeft,
.dayContainer.slideLeft,
.flatpickr-current-month.slideRight,
.dayContainer.slideRight {
  display: none;
}
.flatpickr-calendar.dateIsPicked.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 24px;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  height: 16px;
  line-height: 16px;
}
.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative;
}
.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: calc(3.57% - 1.5px);
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: calc(3.57% - 1.5px);
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #959ea9;
}
.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px;
}
.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.05);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
  top: 33%;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  top: 5px;
  display: inline-block;
  text-align: center;
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 7px;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: initial;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
}
.flatpickr-days,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 315px;
  box-sizing: border-box;
  display: inline-block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow:
    -5px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  pointer-events: none;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.54);
  height: 27.333333333333332px;
  line-height: 24px;
  margin: 0;
  background: transparent;
  text-align: center;
  display: block;
  float: left;
  width: 14.28%;
  font-weight: bold;
  margin: 0;
  padding-top: 3.333333333333333px;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 1px 12px 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
}
.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 45;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.33s cubic-bezier(0, 1, 0.5, 1);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1 1 0%;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0;
}
.hasWeeks .flatpickr-days,
.hasTime .flatpickr-days {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.hasWeeks .flatpickr-days {
  border-left: 0;
}
@media all and (-ms-high-contrast: none) {
  .flatpickr-month {
    padding: 0;
  }
  .flatpickr-month svg {
    top: 0 !important;
  }
}
@-webkit-keyframes flatpickrFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes flatpickrFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Forms */

// input::-webkit-input-placeholder { color: #DDD !important; }
// input:-moz-placeholder { color: #DDD !important; }
// input::-moz-placeholder { color: #DDD !important; }
// input:-ms-input-placeholder { color: #DDD !important; }

.has-warning .form-control {
  // border-color: #a94442 !important;
}
form {
  input {
  }
  fieldset {
    margin-top: 8px;

    legend {
      border: 1px solid #ddd;
      background-color: #f0f0f0;
      padding: 4px 10px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  button[type='submit'] {
    margin-top: 20px;
  }

  .form-group,
  .form-input {
    label {
      font-weight: 400;
      // margin-bottom:10px;
    }
    // margin-bottom:20px;
  }
}

.input-group-addon {
  background-color: #fafafa !important;
  color: #999 !important;
  border-color: #aaa !important;
  border-top-right-radius: 0em;
  border-bottom-right-radius: 0em;
}

textarea:focus,
input:focus,
.uneditable-input:focus,
.flatpickr.active {
  border-color: rgba(0, 0, 255, 0.4) !important;
  box-shadow:
    0 1px 1px rgba(0, 0, 255, 0.075) inset,
    0 0 8px rgba(0, 0, 255, 0.2) !important;
  outline: 0 none !important;
}

.has-feedback label ~ .form-control-feedback {
  top: 30px;
}

.form-control-feedback {
  top: 3px;
  z-index: 55;
}

.has-warning .form-control-feedback {
  color: rgba(251, 176, 87, 0.8);
}

.has-success textarea:focus,
.has-success input:focus,
.has-success .flatpickr.active {
  border-color: rgba(0, 176, 0, 1) !important;
  box-shadow: none;
  outline: 0 none !important;
}

.has-warning textarea:focus,
.has-warning input:focus,
.has-warning .flatpickr.active {
  border-color: rgba(251, 176, 87, 1) !important;
  box-shadow: none;
  outline: 0 none !important;
}

.has-warning .input-group-addon,
.has-warning .form-control {
  border-color: #aaa;
  // background-color: rgba(251, 176, 87, 0.05);
}

.has-success .input-group-addon,
.has-warning .form-control {
  border-color: #aaa;
  // background-color: rgba(251, 176, 87, 0.05);
}

.list-item {
  padding: $spacing-xxs;
  cursor: pointer;
  &:hover {
    background: $gray200;
  }
}

.highlighted {
  background: $gray200;
}
