.checkbox-container {
  text-align: left;
  margin-bottom: $spacing-m;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  > label {
    margin-bottom: $spacing-xxs;
  }
}

input[type='checkbox'] {
  cursor: pointer;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.checkbox-rows {
  @include flexbox;
  @include justify-content(flex-start);
}

.single-checkbox {
  @include flexbox;
  @include align-items(center);
  label {
    padding-left: $spacing-xs;
    font-family: $bold;
    text-transform: uppercase;
  }
}

.checkbox-input,
.proposal-checkbox {
  appearance: none;
  display: none;
  border-radius: 3px;
  margin-top: 0;
  cursor: pointer;
  background-color: #fafbff;
  border: 1px solid gray;
  height: 20px;
  width: 20px !important;
  @include align-self(center);
  display: inline-block;
  margin: 0 !important;
}

.checkbox-input:checked {
  background-color: $orange;
  border-color: darken($orange, 10%);
  border: 1px solid darken($orange, 10%);
  position: relative;
  &:before {
    content: '✔';
    color: #fff;
    position: absolute;
    top: 0;
    left: 3px;
    font-size: $font-size-xs;
  }
}

.proposal-checkbox:checked {
  background-color: #7f9cf5;
  border-color: darken(#7f9cf5, 10%);
  border: 1px solid darken(#7f9cf5, 10%);
  position: relative;
  &:before {
    content: '✔';
    color: #fff;
    position: absolute;
    top: 0;
    left: 3px;
    font-size: $font-size-xs;
  }
}

.checkbox-single-column {
  @include flexbox;
  > div {
    margin-left: 200px;
  }
}

.checkbox-columns {
  column-count: 2;
  .single-checkbox {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.checkbox-three-columns {
  column-count: 3;
  .single-checkbox {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.recurring-schedule-container {
  margin-bottom: $spacing-m;
}

.recurring-schedule-container__column {
  width: 150px;
  margin-right: $spacing-s;
}

.label-padding {
  padding: 0 10px;
}
