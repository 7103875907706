.collapsible-table {
  .collapsible-table-container:first-child {
    border-top: 1px solid #bec0c3;
  }
  .collapsible-table-container:nth-child(even) {
    background: #f3f3f4;
  }
}

.collapsible-table-container {
  transition: all 0.5s ease;
  width: 100%;
  box-sizing: border-box;
  // min-width: 700px;
  min-width: 580px;
  padding: 20px;
  padding-top: 5px;
  border-bottom: 1px solid #bec0c3;
}

.collapsible-table-container.min-w-auto {
  min-width: auto;
}

.collapsible-table-container.min-h-50 {
  min-height: 50px;
}

.collapsible-table-container.closed {
  max-height: 40px;
  overflow: hidden;
}

.collapsible-table-container.open {
  height: auto;
  max-height: 3000px;
  overflow: visible;
}

.collapsible-table-headers-container {
  display: flex;
  justify-content: space-between;
  font-family: $regular;
  color: $dark500;
  align-items: center;
  font-size: 13px;
  margin-bottom: 20px;
  padding-top: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.collapsible-table-first-header {
  color: $dark500;
  text-transform: uppercase;
  font-family: $bold;
  vertical-align: bottom;
  text-align: left;
  padding: 3px 5px;
  font-size: 11px;
  letter-spacing: 1px;
}

.table-toggle {
  color: $orange;
  margin-right: 15px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}

.table-toggle.open {
  transform: rotate(90deg);
}

.collapsible-table-headers {
  display: flex;
  p {
    color: $dark500;
    margin-left: 30px;
    text-transform: uppercase;
    font-family: $bold;
    vertical-align: bottom;
    text-align: left;
    padding: 3px 5px;
    font-size: 11px;
    letter-spacing: 1px;
  }
}

.collapsible-table {
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 5px 3px;
    padding-left: 25px;
    font-family: $regular;
    font-size: 13px;
    color: $dark500;
  }
  td {
    vertical-align: middle;
  }
  td:first-child,
  th:first-child {
    padding-left: 0;
  }
  .align-right {
    text-align: right;
  }
  th {
    font-family: $semibold;
    border-bottom: 1px solid #bec0c3;
  }
  tr:nth-of-type(even) {
    border-bottom: 1px solid #bec0c3;
    border-top: 1px solid #bec0c3;
  }
  tr:last-child {
    border-bottom: none;
  }
}

.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}
