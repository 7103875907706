.edit-group-order-delivery-fee-settings-modal {
  .info-container {
    margin-bottom: 20px;
    p {
      font-family: 'lightitalic';
      color: $gray400;
    }
  }

  .fee-inputs-container {
    margin-left: 25px;

    .fee-value {
      max-width: 100px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .link-text {
      margin-right: 20px;
    }
  }
}
