$modal-height: 80vh;
$modal-heading-height: 40px;
$vertical-content-padding: 20px;
$content-height: calc(
  $modal-height - $modal-heading-height - ($vertical-content-padding * 2)
);
$search-bar-height: 70px;
$footer-height: 80px;
$primary-content-height: calc(
  $content-height - $search-bar-height - $footer-height
);
$list-heading-height: 25px;
$list-container-height: calc(
  $content-height - $search-bar-height - $list-heading-height - $footer-height
);

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fade-in($time: 0.4s) {
  animation: fade-in $time linear forwards;
}

.go-invoice-modal-inner {
  height: $modal-height;
  overflow: hidden;
  .modal-heading {
    height: $modal-heading-height;
  }
}

.group-order-invoice-modal {
  height: $content-height;
  padding: $vertical-content-padding;
  .search-bar-container {
    height: $search-bar-height;
  }

  .search-bar {
    display: flex;
    gap: 20px;
    .search-element {
      max-width: 130px;
    }
  }

  .primary-container {
    height: $primary-content-height;
    display: flex;
    gap: 30px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    height: $list-heading-height;
    .header-text {
      font-family: lightitalic;
    }
  }

  .search-results-container {
    flex: 1;
    height: 100%;
    .search-loading {
      .loader {
        width: 15px;
        height: 15px;
        margin: 0;
      }
    }
    .search-results {
      height: $list-container-height;
      overflow: auto;
    }
  }

  .selected-container {
    flex: 1;
    height: 100%;

    .selected-items {
      height: $list-container-height;
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: min-content;
      gap: 10px;
    }
  }

  .group-order-item {
    @include fade-in(0.2s);
    margin-bottom: 10px;
    display: block;
    border-radius: 5px;
    border: solid 1px $gray200;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
    border: solid 1px darken($gray200, 1%);
    &:hover {
      border: solid 1px darken($gray200, 15%);
    }
    &.selected {
      background-color: lighten($gray200, 15%);
      .item-right {
        visibility: visible;
      }
    }
    .item-left {
      display: block;
      text-align: left;
      .order-number {
        font-family: bold;
        font-size: 110%;
      }
      .order-date {
        font-family: regular;
      }
      .order-location {
        font-family: regular;
      }
      .suborders-count {
        font-family: regular;
      }
    }
    .item-right {
      visibility: hidden;
      svg {
        font-size: 200%;
        font-weight: bold;
        color: lighten($blue200, 0%);
      }
    }
  }

  .selected-item {
    @include fade-in(0.2s);
    display: block;
    border-radius: 5px;
    border: solid 1px $gray200;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
    min-height: 60px;
    background-color: lighten($blue200, 50%);
    &:hover {
      background-color: lighten($blue200, 40%);
    }
    .item-left {
      display: block;
      text-align: left;
      .order-number {
        font-family: bold;
        margin-bottom: 0px;
      }
      .order-date {
        font-family: regular;
        font-size: 90%;
      }
      .order-location {
        font-family: regular;
        font-size: 90%;
      }
      .suborders-count {
        font-family: regular;
        display: none;
      }
    }
    .item-right {
      svg {
        font-size: 180%;
      }
    }
  }

  .footer-container {
    height: $footer-height;
    background-color: white;
    padding-top: 40px;
    .buttons-container {
      display: flex;
      justify-content: flex-end;
      .link-text {
        margin-right: 30px;
        color: gray;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.client-global-settings-section {
  width: 100%;
  border-top: solid 1px $gray200;
  border-bottom: solid 1px $gray200;
  padding: 20px 0 40px;
  margin-bottom: 20px;
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .section-title {
      font-family: bold;
      text-transform: uppercase;
    }
  }
  .invoice-button {
    background-color: lighten($gray200, 15%);
    transition: background-color 0.2s ease;
    padding: 10px 10px;
    border-radius: 3px;
    border: solid 1px $gray200;
    &:hover {
      background-color: lighten($gray200, 10%);
    }
    .button-content {
      display: flex;
      align-items: center;
      svg {
        font-size: 125%;
      }
      & > div {
        margin-left: 5px;
        font-family: bold;
        text-transform: uppercase;
        line-height: 1;
      }
    }
  }
}
