.header-inputs-row {
  display: flex;
  align-items: center;
  .flatpickr-wrapper {
    width: 170px;
    padding-right: 0 !important;
  }
}

.fixed-header-row {
  position: fixed;
  width: 100%;
  background: lightgrey;
}

.orange-highlight {
  background: orange;
}

.page-arrow {
  background: none;
  border: none;
  color: $gray300;
}

.order-table {
  border-spacing: 10px;
  th {
    white-space: nowrap;
  }
  .category {
    display: inline-block;
    margin-right: 5px;
    text-transform: uppercase;
    font-family: $bold;
    font-size: 12px;
  }
}
