.quill {
  width: 100%;
}

.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
  border: 1px solid gray;
}

.ql-container.ql-snow {
  border-radius: 0 0 4px 4px;
  border: 1px solid gray;
  background: #fafbff;
}
