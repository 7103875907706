.accounts-receivable-button-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 15px;
}

.export-csv {
  display: flex;
  img {
    width: 18px;
    margin-right: 10px;
  }
}

.accounting-filter-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
  p {
    margin-right: 5px;
    white-space: nowrap;
  }
  .filter-dropdown {
    font-size: $font-size-xs;
  }
}

.account-receivable-dashboard {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
}

.account-receivable-table-container {
  transition: all 0.5s ease;
  margin-bottom: 12px;
  width: 100%;
  max-width: 1500px;
  border-left: 10px solid $orange;
  padding: 20px;
  padding-top: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
}

.account-receivable-table-container.closed {
  max-height: 40px;
  overflow: hidden;
}

.account-receivable-table-container.open {
  height: auto;
  overflow: visible;
  .accounts-toggle {
    transform: rotate(90deg);
  }
}

.accouont-receivable-table-headers {
  display: flex;
  justify-content: space-between;
  font-family: $bold;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
  padding-top: 10px;
  cursor: pointer;
  p {
    color: $orange;
  }
}

.accounts-toggle {
  color: $orange;
  margin-left: 15px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}

.account-receivable-amounts {
  display: flex;
  p {
    font-family: $regular;
    color: $gray300;
    margin-left: 30px;
    font-size: $font-size-xs;
  }
}

.account-receivable-table {
  width: 100%;
  th,
  td {
    padding: 3px;
    padding-right: 15px;
  }
  th {
    font-family: $bold;
  }
  tr:nth-of-type(even) {
    border-bottom: 1px solid #bec0c3;
    border-top: 1px solid #bec0c3;
  }
  tr:last-child {
    border-bottom: none;
  }
}

.account-receivable-contact {
  display: flex;
  align-items: center;
}

.table-icon {
  width: 16px;
  padding: 1px 3px;
  margin: 0 1px;
  box-sizing: content-box;
}

.contact-hover-container {
  position: relative;
  display: flex;
  align-items: center;
  .contact-hover {
    display: none;
    position: absolute;
    background: #666;
    z-index: 1;
    width: auto;
    padding: 5px 10px;
    top: -35px;
    left: 50%;
    border-radius: 4px;
    transform: translate(-50%);
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      transform: translate(-50%);
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: #666 transparent transparent transparent;
    }
    p {
      color: #fff;
      text-align: center;
      white-space: nowrap;
    }
  }
  .email-link {
    display: flex;
    align-items: center;
  }
  &:hover {
    .contact-hover {
      display: block;
    }
  }
}

.order-link {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}

.invoice-panel {
  background: #fff;
  -webkit-box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.filters-container {
  display: flex;
  border-bottom: 1px solid #a0aec0;
  padding-bottom: 5px;
}

.table-filter {
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 3px;
    font-family: $bold;
  }
}

.invoice-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  display: block;
  overflow-x: auto;
  th {
    background: rgb(0, 25, 64);
    text-transform: uppercase;
    font-size: 12px;
    font-family: $bold;
    color: #fff;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
  }
  td {
    vertical-align: middle;
    background: #fff;
    padding: 5px 10px;
    &:first-child {
      padding-left: 0;
    }
  }
  p {
    font-size: 14px;
  }
  .time-field {
    background: #fff !important;
    font-family: $regular;
    font-size: 14px;
    width: 100px;
    border: 1px solid #a0aec0;
  }
}

.page-number {
  font-family: $regular;
  margin-top: 10px;
}

.invoice-modal {
  width: 100%;
  max-width: 1300px;
  margin: 25px auto;
}

.invoice-table-link {
  text-decoration: underline;
  color: #1e88e5;
  white-space: nowrap;
}
