.radiobutton-container {
  text-align: left;
  @include flexbox;
  @include flex-direction(column);
  margin-bottom: $spacing-l;
}

input[type='radio'] {
  cursor: pointer;
}

.radiobutton {
  @include flexbox;
  @include align-items(center);
  label {
    padding-left: $spacing-xs;
    font-family: $bold;
    text-transform: uppercase;
  }
  input[type='radio'] {
    margin-top: 0;
    appearance: none;
    cursor: pointer;
    background-color: $gray100;
    border: 1px solid $gray200;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    @include align-self(center);
    display: inline-block;
  }
  input[type='radio']:checked {
    background-color: #fff;
    border-color: $orange;
    border: 5px solid $orange;
    position: relative;
  }
}
