.group-orders-master-warnings-modal-content {
  .validation-section {
    margin-bottom: 30px;
  }
  .validation-errors-title {
    font-style: italic;
    margin-bottom: 20px;
  }
  .validation-errors-container {
  }
  .validation-message {
    margin-bottom: 10px;
    border: solid 1px lighten(gray, 30%);
    border-radius: 5px;
    padding: 10px;
    &.is-error {
      background-color: lighten($salmon-pink, 20%);
    }
    &.is-warning {
      background-color: lighten($salmon-pink, 30%);
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    .cancel-button {
      margin-right: 20px;
    }
  }
}

.go-items-counter-container {
  border: solid 1px lighten(gray, 30%);
  border-radius: 5px;
  padding: 10px;
  .items-counter-group {
    display: flex;
    .items-counter-label {
      flex: 1;
      margin-right: 10px;
    }
    .items-counter-value {
      font-weight: bold;
      &.min-not-reached {
        color: red;
      }
      &.min-reached {
        color: green;
      }
    }
  }
}

.last-change-section {
  width: 100%;
  .last-change-container {
    border: solid 1px lighten(gray, 30%);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    .change-content {
    }
    .change-close-button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 10px;
    }
    .change-close-button {
      svg {
        font-size: 1.5rem;
      }
    }
    .no-changes-found {
    }
    .changes-found {
      .change-item {
        display: flex;
        .change-label {
          margin-right: 10px;
          min-width: 100px;
        }
        .change-value {
          font-weight: bold;
        }
      }
    }
  }
  .show-change-button {
    padding: 10px;
    border-radius: 5px;
    border: solid 1px lighten(gray, 30%);
    background-color: lighten(gray, 40%);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}
