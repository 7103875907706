.alert-banner-container {
  display: flex;
  justify-content: space-between;
  > div {
    width: 49%;
  }
}

.action-panel {
  width: 100%;
  margin-bottom: $spacing-xxs;
  color: #fff;
  .panel-heading {
    padding: $spacing-xs $spacing-xs;
    border-radius: 5;
  }
}

.charge-client {
  display: flex;
  flex-direction: column;
  img {
    margin: auto;
    width: 50px;
    padding-bottom: 10px;
  }
  h3 {
    font-family: $bold;
    color: #31bd57;
  }
  .form-field-container__columns {
    margin-top: $spacing-s;
    > div {
      &:before {
        display: none;
      }
    }
  }
  .columns-container {
    align-items: center;
  }
  select {
    background: #fff;
  }
}

.panel-warning,
.panel-success {
  border: none;
  box-shadow: none;
}

.panel-arrow {
  font-size: 10px;
  margin-right: 10px;
}

.panel-success > .panel-heading {
  background: lighten($blue, 27%);
  border: none;
}

.panel-success .panel-body {
  background: lighten($blue, 37%);
  border-top: none;
}

.panel-warning > .panel-heading {
  background: lighten($orange, 25%);
  border: none;
}

.panel-warning .panel-body {
  background: lighten($orange, 33%);
  border-top: none;
}

.panel__inner > a {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.panel__text {
  margin: 0;
  .bold {
    margin-right: $spacing-xxs;
  }
}

.panel__title {
  font-size: $font-size-xs;
  position: relative;
  margin-left: 45px;
}

.proposal-panel .panel__title {
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icon-send.png') no-repeat;
    background-size: 25px;
    width: 25px;
    height: 30px;
    top: -4px;
    left: -40px;
  }
}

.charge-panel .panel__title {
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icon-charge.png') no-repeat;
    background-size: 25px;
    width: 25px;
    height: 30px;
    top: 0;
    left: -40px;
  }
}

.chef-panel .panel__title {
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icon-chef.png') no-repeat;
    background-size: 25px;
    width: 25px;
    height: 30px;
    top: -5px;
    left: -40px;
  }
}

.services-panel .panel__title {
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icon-service.png') no-repeat;
    background-size: 25px;
    width: 25px;
    height: 30px;
    top: 0;
    left: -40px;
  }
}

.survey-panel .panel__title {
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icon-survey.png') no-repeat;
    background-size: 25px;
    width: 25px;
    height: 30px;
    top: -3px;
    left: -40px;
  }
}

.center-panel {
  text-align: center;
  p {
    font-size: $font-size-xs;
  }
}

.complete {
  color: $blue;
  margin-right: 20px;
  font-family: $bold;
}

.currency-radio {
  input {
    width: 110px;
  }
}

#select-payment-method {
  padding: 3px;
}
