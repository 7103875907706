.service-details {
  text-align: center;
  h2.service-title {
    text-align: center;
    margin-top: $spacing-m;
  }
  .form-field-container__columns {
    p {
      width: 170px;
      min-width: 170px;
    }
    input {
      background: #fff !important;
    }
    input[type='text'] {
      min-width: 180px;
      width: 180px;
      background: #fff;
    }
    input[type='text'].numInput {
      width: 100%;
      min-width: auto;
      background: #fff;
    }
    select {
      width: 180px;
      background: #fff;
    }
  }
  .dropdown {
    min-width: 180px;
    width: 180px;
  }
  .full-width-text {
    width: auto;
    text-align: center;
    margin-right: 0;
    margin-bottom: $spacing-xs;
  }
  .has-feedback .form-control {
    padding-right: 0;
    text-align: center;
  }
}
