.login-container {
  height: 100%;
  min-height: 100vh;
  background: url('../images/background2.jpg') center;
  background-size: cover;
  @include flex-center-horizontal-vertical;
}

.login-container__form {
  background: #fff;
  max-width: 450px;
  width: 100%;
  padding: $spacing-l;
  border-radius: 5px;
  box-shadow: 0px 10px 28px -2px rgba(0, 0, 0, 0.62);
  h2 {
    font-family: $bold;
    color: $gray400;
    font-size: $font-size-l;
  }
  input {
    display: block;
    width: 100%;
    margin-bottom: $spacing-s;
    padding: $spacing-xs;
    border: 1px solid $gray200;
  }
  input[type='submit'] {
    @include orange-gradient;
    margin-top: $spacing-m;
    font-size: $font-size-s;
    color: #fff;
    width: auto;
    padding-left: $spacing-l;
    padding-right: $spacing-l;
    border: none;
  }
}
