.scrollable {
  min-height: 39px;
  width: 100%;
  background-color: rgb(215, 215, 215);
  border: 1px solid gray;
}

.arrow-wrap {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin: auto;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.expanded {
  display: flex;
  flex-direction: column;
  padding: 6px;
}

.expanded-selected {
  @extend .expanded;
  background-color: rgb(245, 245, 245);
}
