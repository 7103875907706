@mixin transition($attribute, $time) {
  -moz-transition: $attribute $time ease-in-out;
  -o-transition: $attribute $time ease-in-out;
  -webkit-transition: $attribute $time ease-in-out;
  transition: $attribute $time ease-in-out;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin sidebar-line {
  position: absolute;
  width: 25px;
  height: 5px;
  border-radius: 5px;
  background: lighten($gray200, 30%);
  box-shadow: inset 0px 7px 20px -4px rgba(0, 0, 0, 0.2);
}
@mixin orange-gradient {
  background: $violet; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    $violet 0%,
    $orange 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    $violet 0%,
    $orange 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    $violet 0%,
    $orange 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$violet, endColorstr=$orange,GradientType=1 ); /* IE6-9 */
}

@mixin orange-gradient-vertical {
  background: $violet; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    $violet 0%,
    $orange 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    $violet 0%,
    $orange 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to top,
    $violet 0%,
    $orange 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$violet, endColorstr=$orange,GradientType=1 ); /* IE6-9 */
}

@mixin orange-gradient-reverse {
  background: $violet; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    $orange 0%,
    $violet 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    $orange 0%,
    $violet 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    $orange 0%,
    $violet 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$violet, endColorstr=$orange,GradientType=1 ); /* IE6-9 */
}

@mixin gradient-text {
  background: $violet;
  background: -webkit-linear-gradient(
    left,
    $violet 0%,
    $orange 50%
  ); /* For Chrome and Safari */
  background: -moz-linear-gradient(
    left,
    $violet 0%,
    $orange 50%
  ); /* For old Fx (3.6 to 15) */
  background: -ms-linear-gradient(
    left,
    $violet 0%,
    $orange 50%
  ); /* For pre-releases of IE 10*/
  background: -o-linear-gradient(
    left,
    $violet 0%,
    $orange 50%
  ); /* For old Opera (11.1 to 12.0) */
  background: linear-gradient(
    to right,
    $violet 0%,
    $orange 50%
  ); /* Standard syntax; must be last */
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    background: none;
    color: $violet;
  }
}

@mixin title-description {
  font-family: $light;
  font-size: $font-size-l;
  line-height: 1.3;
}

@mixin flex-center-horizontal-vertical {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
}
// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

// hide things off screen for accessibility
.offscreen {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

@mixin vh-polyfill($property, $vh, $add: null) {
  $media-queries:
    'all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)',
    // iPad with portrait orientation.
    'all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)',
    // iPad with landscape orientation.
    'screen and (device-aspect-ratio: 40/71)',
    // iPhone 5 You can also target devices with aspect ratio.
    'screen and (device-width: 320px) and (device-aspect-ratio: 2/3) and (orientation:portrait)'; // iPhone 4

  $heights: 1024px, 768px, 500px, 480px;

  $i: 1;
  @if $add == null {
    #{$property}: $vh * 1vh;

    @each $media-query in $media-queries {
      @media #{$media-query} {
        #{$property}: round(nth($heights, $i) * $vh / 100);
      }
      $i: $i + 1;
    }
  } @else {
    @if $add > 0 {
      #{$property}: calc(#{$vh * 1vh} + #{$add});
    } @else {
      #{$property}: calc(#{$vh * 1vh} - #{$add * -1});
    }

    @each $media-query in $media-queries {
      @media #{$media-query} {
        #{$property}: #{round(nth($heights, $i) * $vh / 100) + $add};
      }
      $i: $i + 1;
    }
  }
}
