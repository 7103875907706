/*********************** IE specific rules *****************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .page-title {
    background: none;
    color: $violet;
  }
  .logo-link {
    top: 10px;
  }
}

@-moz-document url-prefix() {
  .page-title {
    background: none;
    color: $violet;
  }
}

/*********************** Firefox specific rules *****************************/
@-moz-document url-prefix() {
  .page-buttons-container .status-search select {
    padding: 9px 15px;
  }
  .page-buttons-container .search-field .search-field__input {
    height: 38px;
  }
}

/*********************** Viewport Height Support old Iphones *****************************/

.press-page-container,
.faq-page-container,
.about-page {
  .hero-container {
    @include vh-polyfill(height, 60);
  }
}
.nav-container-hamburger,
.nav-container-hamburger__inner,
.hero-container {
  @include vh-polyfill(height, 100);
}

/***************** Always show scroll bar if there is a scrolling option ***********************/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
