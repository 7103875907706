.filter-query {
  > label:first-child {
    display: block;
    font-family: $bold;
    color: $orange;
    margin-bottom: $spacing-xs;
    font-size: $font-size-s;
  }
  label:nth-child(2) {
    margin-bottom: 5px;
  }
  input {
    margin-bottom: $spacing-s;
  }
}
