.table-responsive {
  min-height: 500px;
}

.table > thead > .table-headers {
  th {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    span {
      width: max-content;
      display: inline-block;
      font-size: 12px;
    }
  }
  th:first-child {
    padding-left: 0;
    padding-right: 0;
  }
}

.table > .table-content > tr > td {
  padding-right: 10px;
  padding-top: 10px;
  font-size: 12px;
}

.dashboard-small-field {
  max-width: 80px;
  width: 80px;
  input {
    width: 70px;
    padding: 0 3px;
    font-size: 12px;
  }
  .rdtPicker {
    width: 150px;
  }
}

.form-field-container {
  .dashboard-small-field {
    input[type='text'] {
      width: 100%;
      min-width: auto;
      max-width: 100px;
    }
  }
}

/** Fields **/

.order-number-cell {
  position: absolute;
  z-index: 100;
  background: lighten($gray300, 10%);
  color: #fff;
  width: 105px;
}

.order-number-container:first-child > .order-number-cell {
  &:before {
    content: 'Order Number';
    width: 105px;
    background: lighten($gray300, 10%);
    color: #fff;
    left: 0;
    top: -45px;
    height: 43px;
    line-height: 43px;
    position: absolute;
    pointer-events: none;
  }
}

.order-number-container {
  textarea.form-control {
    height: 60px;
  }
}

.dashboard-long-text-div {
  overflow-y: scroll;
  padding-right: 0;
}

.dashboard-date-time-picker {
  width: 180px;
}

.dashboard-textarea-div {
  height: 70px;
  overflow-y: scroll;
  .form-control {
    padding: 3px;
  }
}

.dashboard-container {
  padding: $spacing-s;
  padding-top: $spacing-l;
  .table-filters {
    margin-top: 20px;
    .is-searchable {
      width: 280px;
    }
    .Select-control {
      border: 1px solid #aaa;
    }
    .Select-multi-value-wrapper {
      margin-top: 0;
    }
    .Select-arrow-zone {
      .Select-arrow {
        display: none;
      }
      &:before {
        content: '';
        top: 7px;
        left: 0;
        background: url('../images/search.png') no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
        opacity: 0.4;
        position: absolute;
      }
    }
  }
  .table-content {
    center {
      float: left;
      margin-left: 500px;
    }
  }
}

.normal-text-area {
  font-size: 10px;
}

.table > thead > tr > .section-button-container {
  padding: $spacing-xxs;
}

.dashboard-section-button {
  display: block;
  border: none;
  width: 100%;
  margin: 2px;
  padding: $spacing-xxs;
}

.dashboard-save-button {
  margin-right: 3px;
}

.dashboard-clear-updates-button {
  display: block;
  width: 100%;
  margin: 2px;
  padding: 2px;
}

.dashboard-hide-clear-updates-button {
  display: none;
}

.float-order {
  width: 120px;
  min-width: 120px;
}

.float-order-items {
  position: absolute;
  background: lighten($gray300, 10%);
  z-index: 10;
  width: 120px;
  height: 90px;
  p {
    color: #fff;
    margin: 0;
    font-size: $font-size-xxs;
  }
  p:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;
  }
  .bold {
    font-size: $font-size-xs;
  }
}

.order-number-container:first-child .float-order-items {
  &:before {
    content: 'Order Number';
    width: 120px;
    background: lighten($gray300, 10%);
    color: #fff;
    left: 0;
    top: -45px;
    height: 43px;
    line-height: 43px;
    position: absolute;
    text-align: center;
    pointer-events: none;
  }
  height: 115px;
}

.table-filters {
  @include flexbox;
  @include justify-content(center);
  border-bottom: solid 1px $gray200;
  margin-bottom: $spacing-s;
  padding-bottom: $spacing-xxs;
  .filter {
    width: 200px;
    margin-right: $spacing-l;
    z-index: 1001;
  }
}

.table-filters__inner {
  @include flexbox;
  @include align-items(center);
}

.dashboard-clear-updates-button {
  padding: $spacing-xxs $spacing-xs;
}

.date-filters,
.date-filters__inner {
  @include flexbox;
  @include align-items(center);
  p {
    margin: 0;
    padding: 0 $spacing-xxs;
    font-size: $font-size-xs;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.date-filters__inner {
  margin-right: $spacing-l;
  .form-group {
    width: 170px;
    min-width: 170px;
  }
  .glyphicon {
    &:before {
      content: '';
      content: '';
      top: 3px;
      right: 5px;
      background: url('../images/calendar.png') no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      opacity: 0.4;
      position: absolute;
    }
  }
  button {
    margin-left: $spacing-xxs;
  }
}

.page-container {
  margin-right: $spacing-l;
}

.page-container,
.page-selector {
  p {
    margin: 0;
    padding: 0 $spacing-xxs;
  }
  .sema-label {
    padding-left: $spacing-xxs;
  }
  font-size: $font-size-xs;
  @include flexbox;
  @include align-items(center);
}

.save-single-field-buttons {
  @include flexbox;
  @include flex-direction(column);
  width: 150px;
  button {
    &:last-child {
      margin-top: $spacing-xxs;
    }
  }
}

/** Edited Fields **/
.suggested-time {
  background-color: lightblue;
}

.updated-input {
  input,
  textarea {
    background-color: #fcddd9;
  }
}

.dashboard-date-time-picker.updated-input {
  background-color: #fcddd9;
}

select.updated-input {
  background-color: #fcddd9;
}

.dashboard-chefs-container {
  overflow-y: scroll;
}

.dashboard-chef {
  white-space: nowrap;
  font-size: 14px;
  margin-left: 0;
  span {
    background: $gray400;
    color: #fff;
    width: 18px;
    height: 18px;
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    font-size: $font-size-xxs;
    margin-right: $spacing-xxs;
  }
}

/***** Fix for bootstrap ******/
.dashboard-container {
  .flatpickr-current-month {
    background: #fff;
  }
  .slideLeft,
  .slideRight {
    display: none;
  }
}
