.mlb-container {
  width: 100%;
  display: flex;
}

.mlb-sidebar {
  width: 230px;
  height: 100%;
  background: black;
  position: fixed;
  top: 0;
  padding-top: 70px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: $bold;
  font-size: 18px;
  a {
    padding: 10px;
    padding-left: 15px;
    border-radius: 100px 0 0 100px;
    margin-left: 20px;
    margin-bottom: 10px;
    color: #fff;
    display: inline-block;
    width: 100%;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
  .active {
    background: #dfe4ea;
  }
}

.mlb-logo {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background: #fff;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  border: 1px solid #fff;
  img {
    width: 100%;
  }
}

.mlb-content {
  width: 100%;
  margin-left: 230px;
  margin-bottom: 50px;
  padding: 20px;
}

.mlb-panel {
  background: #fff;
  -webkit-box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.mlb-full-panel {
  background: #fff;
  -webkit-box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.mlb-menu-container {
  width: 100%;
  margin-top: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.mlb-add-category {
  font-family: $bold;
  font-size: 15px;
  margin-left: auto;
  background: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  text-transform: uppercase;
  border: 1px solid;
}

.mlb-menu-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid gray;
  p {
    font-family: $bold;
    text-transform: uppercase;
    font-size: 17px;
  }
}

.mlb-add-item {
  color: #fff;
  padding: 7px 10px;
  border-radius: 3px;
  font-family: $bold;
  text-transform: uppercase;
}

.mlb-menu-table {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-family: $bold;
    color: #5f5f5f;
    padding: 0 10px;
  }
  td {
    vertical-align: middle;
    background: #fff;
    padding: 5px 10px;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  tr.mlb-item-bar {
    -webkit-box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
    box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
  }
}

.mlb-table-image {
  border-radius: 4px;
  width: 60px;
  height: 35px;
  overflow: hidden;
  position: relative;
  background: #e2e2e2;
  .camera {
    width: 25px;
  }
  img {
    border-radius: 4px;
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
}

.mlb-item-image {
  border-radius: 4px;
  width: 300px;
  height: 180px;
  overflow: hidden;
  position: relative;
  background: #e2e2e2;
  cursor: pointer;
  .camera {
    width: 35px;
  }
  img {
    border-radius: 4px;
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
}

.mlb-edit-item-button {
  font-family: $bold;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: auto;
  background: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
  border: 1px solid;
}

.mlb-search {
  padding: 5px;
  border: 1px solid gray;
}

.mlb-select {
  .Select-input {
    width: 100%;
    input {
      width: 100% !important;
      border: none;
      line-height: 30px;
    }
    input:focus {
      border: none;
      border-color: none !important;
    }
  }
}

.mlb-primary-button {
  font-family: $bold;
  font-size: 14px;
  letter-spacing: 1px;
  background: #001940;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
  border: 1px solid;
}

.mlb-select {
  width: 100%;
}

.modal-outer {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 130;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mlb-modal {
  background: #fff;
  border-radius: 4px;
  margin: 30px 20px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal-inner {
  background: #fff;
  border-radius: 4px;
  margin: 30px 20px;
  max-width: 700px;
  width: 100%;
  position: relative;
}

.modal-close {
  width: 15px;
  cursor: pointer;
}

.modal-heading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-radius: 4px 4px 0 0;
  @include orange-gradient;
  p {
    color: #fff;
    font-family: $bold;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.mlb-modal__inner {
  width: 420px;
  margin: 25px auto;
}

.mlb-modal__inner_big {
  width: 620px;
  margin: 25px auto;
}

.mlb-input-container {
  display: flex;
  flex-direction: column;
  label {
    font-family: $bold;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 4px;
  }
  input,
  textarea {
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    font-size: 17px;
    font-family: $regular;
    color: black;
    box-sizing: border-box;
  }
  .dark {
    background-color: #e0e0e0;
  }
}

.mlb-modal-save {
  font-family: $bold;
  text-transform: uppercase;
  font-size: 13px;
  color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
}

.mlb-modal-delete {
  font-family: $bold;
  text-transform: uppercase;
  font-size: 13px;
  color: #b30303;
  border: 1px solid #b30303;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 15px;
}

.mlb-image-uploader {
  width: 100%;
  height: 100%;
  background: none;
  opacity: 0;
}

.mlb-modal-subtitle {
  text-transform: uppercase;
  font-family: $bold;
  font-size: 18px;
}

.mlb-kit-included-items {
  margin-bottom: 35px;
}

.mlb-item-number {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #fff;
  text-align: center;
  font-family: $bold;
  line-height: 25px;
}

.table-trash {
  cursor: pointer;
  width: 15px;
  height: auto;
}

.mlb-modal-button {
  font-family: $bold;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px 20px;
  border: 1px solid;
  background: #f1f1f1;
}

.mlb-radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-family: $regular;
  color: black;
  font-size: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}

.mlb-dropdown {
  border: 1px solid gray;
  padding: 7px;
  border-radius: 4px;
  font-size: 17px;
  font-family: $regular;
  color: black;
  box-sizing: border-box;
  background: #fff;
}
