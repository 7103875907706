$violet: #ee346d;
$orange: #ff624d;
$yellow: #ffffbe;
$red: #ffd9d9;
$purple: #7635ee;
$pink: #f89eac;
$salmon-pink: #f56475;
$pink-red: #e8264b;
$light-purple: #7088ef;
$light-blue: #f0f6ff;
$blue: #5e81d2;
$neon-blue: #2eb9f0;
$emerald: #2dc59f;

$light-green: #d5ffc6;
$green: #35ff69;
$dark-green: #3fe169;
$darker-green: #3dc573;
$dark-yellow: #f6c063;

/******** Greens *********/
$blue100: #80deea;
$blue200: #00acc1;
$blue300: #0097a7;

/******** Grays **********/
$gray100: #eff1f4;
$gray200: #d1d1d1;
$gray300: #516477;
$gray400: #3d4853;
$dark500: #303131;

/*** Colors for marketplace food tags ***/
$vegetarian: #4aa57d;
$vegan: #ff9180;
$gluten-free: #f67399;
$gluten: #801010;
$dairy: #228082;
$nuts: #c2af0b;
$soy: #8087c6;
$eggs: #c68be4;

.vegetarian {
  color: $vegetarian;
}

.vegan {
  color: $vegan;
}

.gluten-free {
  color: $gluten-free;
}

.gluten {
  color: $gluten;
}

.dairy {
  color: $dairy;
}

.nuts {
  color: $nuts;
}

.soy {
  color: $soy;
}

.eggs {
  color: $eggs;
}

.red-text {
  color: $pink-red;
}

.blue-text {
  color: $blue;
}
