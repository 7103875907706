.popup-calculator-title {
  font-family: $bold;
  color: $gray400;
  input {
    margin-left: 10px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    width: 50px;
    background: #fafbff;
  }
}

.popup-table {
  margin: 20px 0;
  width: 100%;
  color: $gray400;
  font-family: $regular;
  border: 1px solid gray;
  border-radius: 4px;
  th {
    font-family: $bold;
    background: #eff1f6;
    padding: 8px;
    border-right: 1px solid gray;
  }
  td {
    padding: 8px;
    border-right: 1px solid gray;
  }
  p {
    padding-top: 5px;
  }
}
