.delivery-settings-summary {
  width: 100%;
  border-top: solid 1px $gray200;
  padding: 20px 0 0;
  margin-bottom: 20px;
  .summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      font-family: bold;
      text-transform: uppercase;
    }
    .edit-button {
      background-color: lighten($gray200, 5%);
      transition: background-color 0.2s ease;
      padding: 5px 10px;
      border-radius: 3px;
      &:hover {
        background-color: $gray200;
      }
      .button-content {
        display: flex;
        align-items: center;
        svg {
          font-size: 125%;
        }
        & > div {
          margin-left: 5px;
          font-family: bold;
          text-transform: uppercase;
          line-height: 1;
        }
      }
    }
  }
  .summary-description {
    p {
      font-family: regular;
      font-size: 100%;
    }
    .standard-fee-info {
      margin-top: 10px;
      font-family: lightitalic;
    }
  }
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    font-family: bold;
    text-transform: uppercase;
  }
  .edit-button {
    background-color: lighten($gray200, 5%);
    transition: background-color 0.2s ease;
    padding: 5px 10px;
    border-radius: 3px;
    &:hover {
      background-color: $gray200;
    }
    .button-content {
      display: flex;
      align-items: center;
      svg {
        font-size: 125%;
      }
      & > div {
        margin-left: 5px;
        font-family: bold;
        text-transform: uppercase;
        line-height: 1;
      }
    }
  }
}
