.chef-data-tables {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.chef-capacity-table {
  width: 70%;
}

.cuisine-list {
  text-align: left;
  text-transform: capitalize;
  font-size: 11px;
  line-height: 1.2;
}

.priority-type {
  color: $blue;
  font-size: 10px;
  font-family: $bold;
  text-transform: uppercase;
  margin-left: 5px;
}

.chef-table-header {
  border-radius: 0;
  appearance: none;
  background: $gray100;
  border: 1px solid $gray200;
  border-bottom: 1px solid $gray400;
  padding: $spacing-xxs;
  text-align: center;
  font-family: $semibold;
}

.chef-columns {
  border-left: 1px solid $gray400;
  display: flex;
  flex-wrap: wrap;
}

.chef-capacity {
  text-transform: capitalize;
  padding: 2px 5px;
  padding-right: 10px;
  width: 25%;
  border-bottom: 1px solid $gray400;
  border-right: 1px solid $gray400;
  display: flex;
  justify-content: space-between;
}

.breakword {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}

.chef-capacity {
  background: $gray100;
}

.chef-capacity.dark-red {
  background: #f26b6d;
}

.chef-capacity.green {
  background: #8dff85;
}

.chef-capacity.dark-orange {
  background: #ff8284;
}

.chef-capacity.orange {
  background: #f2c46b;
}

.chef-capacity.yellow {
  background: #f2f06b;
}

.please-use-table {
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  margin-bottom: 30px;
}

.please-use-columns {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .please-use-item:nth-child(even) {
    background: $gray100;
  }
}

.please-use-item {
  padding: 2px 5px;
  padding-right: 10px;
  break-inside: avoid;
  width: 270px;
  border-bottom: 1px solid $gray200;
}

.please-use-chef {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $semibold;
  font-size: 13px;
}

.unavailable-chefs {
  margin-top: 8px;
  font-size: 12px;
}

.unavailable-chef {
  display: inline-block;
  margin-right: 3px;
}

.unavailable-title {
  font-family: $semibold;
}

.order-chart {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  margin-bottom: 90px;
}

.large-height {
  height: 250px;
}

.small-height {
  height: 120px;
}

.chef-section-title {
  font-size: $font-size-xs;
  text-align: center;
  font-family: $semibold;
  color: $gray400;
}

.legend {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  p {
    font-size: $font-size-xxs;
  }
}

.legend__inner {
  display: flex;
  width: 700px;
  justify-content: space-between;
}

.legend-color {
  width: 30px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.legend-color.dark-red {
  background: #f26b6d;
}

.legend-color.orange {
  background: #f2c46b;
}

.legend-color.green {
  background: #3dc573;
}

.booked-menus-table {
  margin: 0 auto;
  tr {
    border-bottom: 1px solid $gray200;
  }
  th {
    color: $blue;
    font-size: 11px;
    text-transform: uppercase;
    font-family: $bold;
  }
  tr:nth-child(even) {
    background: $gray100;
  }
  td,
  th {
    padding: 5px;
    padding-right: 30px;
  }
}
