.page-fixed {
  display: flex;
  padding: 20px 0;
  left: 230px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  bottom: 0;
  background: #eff1f6;
  justify-content: center;
}

.page-fixed button {
  margin: 0 5px;
}
