.sort-container {
  .add-button {
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-left: 0;
  }
}

.sortable-table {
  border: 1px solid $gray200;
  padding: $spacing-m;
  margin-bottom: $spacing-xs;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.22);
  @include flexbox;
  @include align-items(center);
  .Select-input {
    opacity: 0;
  }
  h3 {
    font-size: $font-size-xs;
    color: $gray400;
    font-family: $regular;
  }
}

.sortable-table__inner {
  width: 100%;
  .Select {
    margin-bottom: $spacing-xs;
  }
  .Select-control {
    border-radius: 0;
    background: $gray100;
  }
}

.sortable-arrows {
  margin-right: $spacing-s;
  .arrow {
    font-size: $font-size-m;
    cursor: pointer;
    color: $gray200;
  }
}

.sortable-details {
  p {
    width: auto;
    text-align: left;
    font-size: $font-size-xxs;
  }
  .badge {
    margin-left: $spacing-xxs;
  }
}

.Select-menu-outer {
  .Select-menu {
    background: #fff;
  }
}

.item-sort {
  width: 13px;
  margin-left: 3px;
}
