.chef-payouts-modal {
  max-width: 500px;
}
.modal-header {
  @include orange-gradient;
  h4 {
    color: #fff;
  }
  button {
    opacity: 0.7;
  }
  button span {
    color: #fff;
  }
}

.panel-body {
  h4 center {
    color: $orange;
    font-family: $bold;
    font-size: $font-size-s;
  }
}

.user-modal {
  .delete-button {
    float: left;
  }
}

.user-modal-container {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  width: 100%;
  center {
    max-width: 350px;
    width: 100%;
  }
  .input-group {
    width: 100%;
  }
  .input-group .form-control:first-child {
    border-radius: 3px;
    width: 100%;
  }
}

.popup-modal {
  h3 {
    text-align: left;
    font-size: $font-size-xs;
  }
}

.modal-dialog {
  width: 600px;
}

.modal-body {
  padding: $spacing-l 0;
}

.form-column-container {
  @include flexbox;
  margin-bottom: $spacing-xl;
}

.form-column-container__single {
  width: 100%;
  &:first-child {
    width: 180px;
    min-width: 180px;
    margin-right: $spacing-l;
  }
}

.input-group {
  width: 100%;
}

.service-textarea {
  margin-top: $spacing-s;
}

textarea.form-control {
  height: 100px;
}
