.children-container {
  .survey-page {
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    .page-title {
      margin-bottom: 0;
    }
  }
  .tabs-container {
    overflow-x: scroll;
    max-width: 1800px;
    min-height: 500px;
  }
}

.survey-table-container {
  min-height: 500px;
}

.first-header-row {
  font-family: $semibold;
}

.survey-table-container {
  p {
    padding: 0;
    margin: 0;
    font-family: $regular;
  }
  .date-label {
    padding: 0 5px;
  }
  .survey-name {
    font-size: $font-size-s;
    font-family: $semibold;
    margin-right: 30px;
  }
  table {
    width: 100%;
  }
  th,
  td {
    border-left: 1px solid $gray200;
    border-right: 1px solid $gray200;
    border-bottom: 1px solid $gray200;
  }
  th {
    background: $gray100;
    border-bottom: 1px solid $gray200;
    padding: 10px;
    max-width: 300px;
    vertical-align: middle;
  }
  td {
    padding: 10px;
    padding-bottom: 20px;
    width: 200px;
    overflow-y: scroll;
    div {
      overflow-y: scroll;
    }
  }
  .list-title {
    font-family: $semibold;
    margin-bottom: 5px;
  }
  .list-item {
    padding: 3px 0;
    margin-bottom: 3px;
  }
}

.survey-dashboard {
  td {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid $gray200;
    height: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px;
  }
  th {
    text-align: center;
  }
  th.date {
    min-width: 145px;
  }
  th.order-number {
    min-width: 80px;
  }
  th.rating {
    width: 50px;
    max-width: 50px;
  }
  th.total {
    min-width: 80px;
    border-top: 0;
  }
  th.name {
    min-width: 160px;
    text-align: center;
  }
  th.chef-feedback {
    width: 200px;
  }
  th.cc-feedback {
    width: 150px;
  }
  th.rep-feedback {
    width: 100px;
    white-space: nowrap;
  }
  td.survey-order-info {
    padding: 5px 10px;
    text-align: left;
  }
  td.survey-order-total {
    text-align: right;
    padding-right: 15px;
  }
}

.survey-list-view {
  display: flex;
  th {
    min-width: 150px;
  }
  th.list-question {
    min-width: 300px;
  }
}

.center-cell-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey-filters-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
  input.flatpickr {
    padding-left: 5px;
  }
}

.survey-filter {
  display: flex;
  align-items: center;
  position: relative;
  p {
    padding-right: 5px;
  }
  &:before {
    content: '';
    top: 7px;
    right: 8px;
    background: url('../images/search.png') no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    position: absolute;
    z-index: 1;
  }
}

.input-container {
  input {
    border-radius: 0;
    appearance: none;
    background: $gray100;
    border: 1px solid $gray200;
    padding: 7px;
    text-align: left;
    width: 250px;
    border-radius: 4px;
    &::placeholder {
      color: lighten($gray300, 20%) !important;
    }
  }
}

.sort {
  margin-left: 10px;
  cursor: pointer;
  padding: 2px;
  display: inline-block;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: lighten($gray300, 30%);
  .filter-up-arrow,
  .filter-down-arrow {
    margin-left: 0;
    cursor: pointer;
  }
  .active {
    color: $gray400;
  }
}

.survey-list-container {
  margin-top: $spacing-s;
  width: 200px;
  min-width: 200px;
  margin-right: $spacing-s;
  font-size: 16px;
}

.survey-success-color {
  background: $light-green;
  cursor: pointer;
}

.survey-warning-color {
  background: $yellow;
  cursor: pointer;
}

.survey-danger-color {
  background: $red;
  cursor: pointer;
}

.download-csv {
  background: #00acc1;
  border: none;
  color: #fff;
  font-family: $bold;
  padding: 9px 15px;
  border-radius: 4px;
  margin-left: 10px;
  &:hover,
  &:active {
    text-decoration: none;
    color: #fff;
  }
}
