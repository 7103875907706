.modal-dialog {
  width: 1100px;
}

.clear-form {
  position: absolute;
  right: 15px;
  top: 10px;
  background: none;
  border: none;
  font-size: $font-size-xxxs;
  font-family: $semibold;
  text-decoration: underline;
}

.clear-form.left {
  left: 10px;
  top: 5px;
}

.columns-container {
  @include flexbox;
}

.columns-container__single {
  width: 100%;
  padding: 0 $spacing-m;
}

.form-group {
  margin-bottom: 0;
}

.form-field-container {
  h2 {
    color: darken($gray400, 20%);
    font-size: $font-size-s;
    font-family: $semibold;
    padding: 0;
    margin-top: $spacing-xl;
    text-align: center;
  }
  .columns-top {
    margin-top: 0;
  }
  p {
    font-family: $regular;
    font-size: $font-size-xs;
    text-align: right;
    margin: 0;
    width: 150px;
    min-width: 150px;
    margin-right: $spacing-s;
    line-height: 1.1;
  }
  .form-field-container__columns {
    @include flexbox;
    @include align-items(center);
    margin-bottom: $spacing-xs;
  }
  .form-field-container__row {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }
  .child-menu-items-list {
    @include align-items(flex-start);
  }
  .form-field-container-buttons {
    margin-bottom: $spacing-xs;
    margin-left: 165px;
    @include flexbox;
    @include align-items(center);
    .add-button {
      padding: 0;
      text-align: left;
    }
  }
  .flex-top {
    @include align-items(flex-start);
  }
  .add-button,
  .cancel-button,
  .delete-button {
    padding: 0;
    background: none;
    border: none;
    text-decoration: underline;
    color: $blue;
    text-transform: uppercase;
    font-size: $font-size-xxxs;
    font-family: $semibold;
    margin-left: $spacing-xs;
    cursor: pointer;
    text-align: left;
  }
  .cancel-button {
    color: $gray400;
  }
  .delete-button {
    color: $pink-red;
  }
  .dropdown {
    position: relative;
    &:before {
      content: '';
      z-index: 5;
      right: 10px;
      position: absolute;
      margin-top: 12px;
      width: 10px;
      height: 10px;
      border-style: solid;
      pointer-events: none;
    }
  }
  .dropdown.updated-input {
    input {
      background-color: #fcddd9;
    }
  }
  .btn-group.open .dropdown-toggle {
    box-shadow: none;
    background: $gray100;
  }
  .bootstrap-dropdown {
    width: 100%;
    appearance: none;
    border-radius: 0;
    background: $gray100;
    border: 1px solid $gray200;
    padding: $spacing-xxs;
    .caret {
      display: none;
    }
  }
  .bootstrap-select {
    .Select-control {
      border-radius: 0;
      background: $gray100;
      border: 1px solid $gray200;
    }
    .Select-arrow-zone {
      display: none;
    }
    .Select-clear {
      display: none;
    }
    .Select-input {
      opacity: 0;
    }
  }
  .open-arrow {
    margin-top: $spacing-m;
    div {
      position: relative;
      &:before {
        content: '▼';
        position: absolute;
        font-size: $font-size-xxxs;
        color: $blue;
        top: -16px;
        text-align: center;
        width: 100%;
      }
    }
  }
  input,
  select,
  textarea {
    border-radius: 0;
    appearance: none;
    background: $gray100;
    border: 1px solid $gray200;
    padding: $spacing-xxs;
    text-align: center;
    width: 220px;
    box-shadow: none;
  }
  input {
    text-align: left;
  }
  input[type='text'] {
    min-width: 220px;
  }
  input[type='text'].numInput {
    width: 100%;
    min-width: auto;
  }
  textarea {
    text-align: left;
    margin-bottom: 0;
    height: 70px;
    min-width: 220px;
  }
  .dropdown-form {
    input,
    textarea {
      background: $light-blue;
    }
    .form-field-error ~ input {
      border: 1px solid $pink-red !important;
      background: lighten($pink-red, 45%) !important;
    }
  }
  .disabled {
    &:before {
      content: '';
      right: $spacing-xs;
      position: absolute;
      margin-top: 12px;
      width: 10px;
      height: 10px;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: $gray200 transparent transparent transparent;
    }
    select {
      background: lighten($gray100, 4%);
      border-color: lighten($gray200, 10%);
      cursor: not-allowed;
    }
  }
  .disabled-input {
    input[type='text']:disabled {
      background: lighten($gray100, 4%);
      border-color: lighten($gray200, 10%);
      color: $gray200;
      cursor: not-allowed;
    }
  }
  .align-right {
    text-align: right;
  }
  .form-field-error,
  .date-error {
    position: absolute;
    font-size: 11px;
    color: $pink-red;
    width: 210px;
    margin-right: 0;
    text-align: left;
    line-height: 1.2;
    top: 2px;
    left: 4px;
    pointer-events: none;
    z-index: 2;
  }
  .form-field-error ~ div input,
  .form-field-error ~ input,
  .form-field-error ~ select {
    border: 1px solid $pink-red;
    background: lighten($pink-red, 45%);
  }
  .date-error ~ .flatpickr-wrapper .flatpickr-input {
    border: 1px solid $pink-red !important;
    background: lighten($pink-red, 45%) !important;
  }
}

.order-items-column {
  .dropdown {
    position: relative;
    width: 220px;
    &:before {
      content: '';
      z-index: 5;
      right: $spacing-xs;
      position: absolute;
      margin-top: 12px;
      width: 10px;
      height: 10px;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: $gray300 transparent transparent transparent;
    }
    input,
    select {
      cursor: pointer;
      border-radius: 0;
      appearance: none;
      background: $gray100;
      border: 1px solid $gray200;
      padding: $spacing-xxs;
      text-align: center;
      width: 100%;
      // &::placeholder {
      //   color: lighten($gray300, 20%) !important;
      // }
    }
    ul {
      position: absolute;
      background: #fff;
      border: 1px solid $gray200;
      width: 100%;
      height: 200px;
      overflow-y: scroll;
      z-index: 100;
      padding: $spacing-xxs;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.22);
    }
    li {
      cursor: pointer;
      margin-bottom: $spacing-s;
    }
  }
  .form-field-error {
    position: absolute;
    font-size: 11px;
    color: $pink-red;
    width: 210px;
    margin-right: 0;
    text-align: left;
    line-height: 1.2;
    top: -2px;
    pointer-events: none;
    z-index: 2;
  }
  .form-field-error ~ div input,
  .form-field-error ~ input,
  .form-field-error ~ select {
    border: 1px solid $pink-red;
    background: lighten($pink-red, 45%);
  }
}

.form-group {
  position: relative;
}

.input-container {
  position: relative;
  @include flexbox;
  @include justify-content(center);
}

.dropdown {
  .form-group {
    position: relative;
    > label {
      display: none;
    }
  }
}

.input-container {
  position: relative;
}

.flex-center-horizontal {
  @include justify-content(center);
}

.carousel {
  margin-top: $spacing-s;
  width: 100%;
}

.carousel-indicators {
  bottom: 30px;
}

.carousel-inner {
  height: 300px;
  button {
    position: absolute;
    background: white;
    padding: 10px;
    border-radius: 5px;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
  }
  img {
    width: 100%;
  }
}

.child-menu-items-list {
  li {
    color: $blue;
    margin-bottom: $spacing-xxs;
    cursor: pointer;
    text-align: left;
  }
  .child-category {
    color: $gray400;
    font-family: $bold;
    padding-right: $spacing-xs;
    font-size: $font-size-xxs;
  }
  .close-x {
    color: $pink-red;
    margin-left: $spacing-xs;
    font-family: $bold;
    cursor: default;
  }
}

.multiple-forms-divider {
  .form-field-container:not(:first-child) {
    margin-top: $spacing-l;
  }
}

.delete-list {
  font-size: $font-size-xxs;
  margin-bottom: $spacing-s;
  .columns-container {
    margin-bottom: $spacing-xxs;
  }
  span {
    color: $pink-red;
    text-align: left;
    width: 200px;
    max-width: 200px;
  }
}

.error-container {
  position: relative;
}

/******** Custom form modal sizes ************/
.order-modal {
  width: 1150px;
}

.small-modal {
  width: 500px;
}
