html,
body,
.body-container,
.sidebar-container,
#app,
.app-container,
.app-container > div {
  min-height: 100vh;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

#app > div {
  height: 100%;
}

html {
  background: #eff1f6;
}

body {
  overflow-x: hidden;
  background: #fff;
  font-family: $regular;
  color: $gray400;
}

h2 {
  font-size: $font-size-xl;
  @media screen and (max-width: $screen-size-l) {
    font-size: $font-size-l;
  }
  @media screen and (max-width: $screen-size-m) {
    font-size: $font-size-m;
  }
}

.round-button,
.round-button-white {
  margin-top: $spacing-l;
  padding: $spacing-xs $spacing-l;
  border-radius: 50px;
  min-width: 200px;
  color: #fff;
  font-family: $light;
  font-size: $font-size-s;
  border: none;
  @include orange-gradient;
  display: block;
  cursor: pointer;
  @media screen and (max-width: $screen-size-m) {
    font-size: $font-size-s;
    min-width: 170px;
  }
}

.round-button-white {
  background: #fff;
  color: $gray400;
}

.round-button--small {
  margin-top: $spacing-m;
  min-width: 150px;
  font-size: $font-size-xs;
}

.gray-bg {
  background: $gray100;
}

.gradient-bg {
  @include orange-gradient;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }
}

.violet {
  color: $violet;
}

.gradient-text {
  @include gradient-text;
}

.full-width-image {
  width: 100%;
  display: block;
}

.body-container {
  @include flexbox;
  background: #eff1f6;
}

.children-container {
  min-height: 100vh;
  margin-top: 30px;
  width: 100%;
  background: #eff1f6;
  @include flexbox;
  @include justify-content(center);
}

.children-container::-webkit-scrollbar {
  display: none;
}

.children-container__inner {
  @include flexbox;
  @include justify-content(center);
  max-width: 1500px;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  overflow: scroll;
  height: fit-content;
  margin-top: $spacing-m;
  margin-bottom: $spacing-xl;
  padding: $spacing-l;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.22);
  > div {
    width: 100%;
  }
}

.page-title {
  text-align: center;
  font-size: $font-size-xl;
  @include gradient-text;
  margin: auto;
  margin-bottom: 20px;
  width: fit-content;
}

.page-columns {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
}

.orders-column {
  @include justify-content(flex-start);
}

.page-buttons-container {
  width: 200px;
  min-width: 200px;
  margin-right: 40px;
  @include align-self(flex-start);
  @include flexbox;
  @include justify-content(center);
  @include flex-direction(column);
  .add-button {
    margin-bottom: $spacing-s;
    a {
      border-radius: 5px;
      color: #fff;
      width: 100%;
      text-align: center;
      font-size: $font-size-xs;
      padding: $spacing-xs $spacing-s;
      background: $orange;
      @include transition(background, 0.2s);
      background-position: 0px;
      display: inline-block;
    }
    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
      background-position: 123px;
      background: $violet;
    }
  }
  .status-search {
    select {
      width: 100%;
      margin-bottom: $spacing-s;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-right: $spacing-xs;
      padding: 11px $spacing-s;
      cursor: pointer;
      font-size: $font-size-xs;
      //margin: 0 0 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 100px;
      border: 1px solid $gray200;
      border-radius: 3px;
      display: inline-block;
      &:after {
        content: '';
      }
    }
  }
  .search-field {
    width: 100%;
    margin-right: $spacing-l;
    .search-field__input {
      height: 40px;
      //padding: 18px $spacing-s;
      font-size: $font-size-xs;
    }
    .search-input-field__results {
      margin-top: $spacing-xs;
    }
    .search-input-field__results > div {
      height: 100% !important;
      overflow: hidden !important;
    }
    .search-field__input::placeholder {
      color: darken($gray200, 10%) !important;
    }
    .search-field__input::-moz-placeholder {
      color: darken($gray200, 10%) !important;
    }
  }
}

.client-page {
  max-width: 1400px;
  .page-buttons-container {
    width: 220px;
    min-width: 220px;
  }
}

.status-search {
  position: relative;
  &:after {
    content: '▼';
    position: absolute;
    font-size: 20px;
    color: lighten($gray300, 20%);
    top: 8px;
    right: 10px;
  }
}

.orange-button {
  margin-bottom: $spacing-s;
  border-radius: 3px;
  border: none;
  color: #fff;
  width: auto;
  text-align: center;
  font-size: $font-size-xs;
  padding: $spacing-xs $spacing-s;
  background: $orange;
  @include transition(background, 0.2s);
  background-position: 0px;
  display: inline-block;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    background: $violet;
    color: #fff;
  }
}

.button-base {
  border: none;
  border-radius: 3px;
  color: #fff;
  line-height: 1;
  margin: 0 $spacing-s;
  text-align: center;
  font-size: $font-size-xs;
  padding: $spacing-xs $spacing-s;
  background: $orange;
  border-bottom: darken($orange, 10%) 3px solid;
  @include transition(background, 0.2s);
  background-position: 0px;
  display: inline-block;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    background-position: 123px;
    background: $violet;
    border-bottom: darken($violet, 10%) 3px solid;
    color: #fff;
  }
}

.red {
  background: $pink-red;
  border-bottom: darken($pink-red, 15%) 3px solid;
  &:hover,
  &:active,
  &:focus {
    background: darken($pink-red, 15%);
    border-bottom: darken($pink-red, 25%) 3px solid;
  }
}

.gray {
  background: lighten($gray300, 10%);
  border-bottom: $gray300 3px solid;
  &:hover,
  &:active,
  &:focus {
    background: darken($gray300, 10%);
    border-bottom: darken($gray300, 20%) 3px solid;
    color: #fff;
  }
}

.client-contacts {
  span {
    display: block;
    margin-bottom: $spacing-xs;
  }
}

.chef-image {
  img {
    margin: auto;
    width: 300px;
    border-radius: 300px;
  }
}

.rbc-event-content {
  color: #fff;
}

.badge-cuisine,
.badge-meal-period {
  font-size: $font-size-xs;
}

.center {
  text-align: center;
}

.center-horizontal-vertical {
  @include flex-center-horizontal-vertical;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-top {
  align-items: flex-start;
}

.clickable {
  td {
    cursor: pointer;
  }
}

.flex-align-top {
  @include align-self(flex-start);
}

.confirmation-text {
  text-align: center;
  font-size: $font-size-xs;
  padding: 0 5px;
}

.confirmation-link {
  text-align: center;
  font-size: $font-size-xs;
  padding: 0 5px;
  color: blue;
}

.space-medium {
  height: 50px;
}

.link {
  color: $blue;
}

.download-pdf {
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: $blue;
  padding: 3px;
  padding-left: 0;
  white-space: nowrap;
  width: 100px;
  margin-bottom: 3px;
  img {
    width: 15px;
    margin-right: 3px;
  }
}

.align-left {
  text-align: left;
}

.p-10 {
  padding: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-50 {
  padding-bottom: 50px;
}

.p-r-20 {
  padding-right: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.min-w-200 {
  min-width: 200px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.small-divider {
  height: 20px;
}

.medium-divider {
  height: 30px;
}

.large-divider {
  height: 50px;
}

.relative {
  position: relative;
}

.m-l-auto {
  margin-left: auto;
}

.columns-container {
  display: flex;
  justify-content: space-between;
}

.column-single {
  width: 45%;
  min-width: 45%;
}

.w-100 {
  width: 100%;
}

.min-w-auto {
  min-width: auto;
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.flex-fix-30 {
  &:after {
    content: '';
    margin-right: 30%;
  }
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.white {
  color: #fff;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

// Chrome scrollbar
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #1e202a;
  border: 1px solid transparent;
  border-radius: 50px;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rbga(0, 0, 0, 0.2);
}

// Firefox scrollbar
@-moz-document url-prefix() {
  .scroller {
    scrollbar-width: thin;
    scrollbar-color: #1e202a;
  }
}
